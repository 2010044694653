
import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";

 function RequireAuthAdministrator({ children }) {
    const [status, setStatus] = useState(true)
    
    return status === true ? children : <Navigate to="/login" replace />;
    // return true
}

export default RequireAuthAdministrator