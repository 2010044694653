import React from 'react'
import styles from "./../../Res/css/TaxInvoice.module.css";
import ReactToPrint from 'react-to-print';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import PendingActionsIcon from '@mui/icons-material/PendingActions';

import { connect, useDispatch } from 'react-redux';
import Header from './Header/Header';
import Footer from './Header/Footer';
import HeaderCustomer from './Header/HeaderCustomer';
import FooterContent from './Header/FooterContent';
import BillBodyLayerHeader from './Header/BillBodyLayerHeader';
import BillBodyLayerLoop from './Header/BillBodyLayerLoop';
import { useEffect } from 'react';
import { SelectTaxInvoice } from '../../API/Action/Company.action';
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import { useRef } from 'react';
import BillBodyLayerHeaderItems from './Header/BillBodyLayerHeaderItems';
import Loading from '../../Components/Loading';



class ComponentToPrint extends React.Component {
    state = {
        countRow: this.props.body.length
    }

    render() {

        return (
            <div >

                {
                    this.props.body ? this.props.body.length <= 4 ?
                        <div className={styles.pageView} >
                            <Header />
                            <HeaderCustomer />
                            <BillBodyLayerHeader data={this.props.body} />
                            <FooterContent />
                            <Footer page='1' total='1' />
                        </div>
                        :
                        <div className={styles.pageView} >
                            <Header />
                            <HeaderCustomer />
                            <BillBodyLayerHeaderItems data={this.props.body} />
                            <Footer page='1' total='2' />
                        </div>
                        : <></>
                }

                {
                    this.props.body ? this.props.body.length > 4 ?
                        <div className={styles.pageView} >
                            <Header />
                            <BillBodyLayerLoop data={this.props.body} />
                            <FooterContent />
                            <Footer page='2' total='2' />
                        </div>
                        : <></> : <></>
                }

            </div>
        );
    }
}



class ComponentToPrintBlock extends React.Component {
    state = {
        countRow: this.props.body.length
    }

    render() {

        return (
            <div >

                {
                    this.props.body ? this.props.body.length <= 4 ?
                        <div className={styles.page} >
                            <Header />
                            <HeaderCustomer />
                            <BillBodyLayerHeader data={this.props.body} />
                            <FooterContent />
                            <Footer page='1' total='1' />
                        </div>
                        :
                        <div className={styles.page} >
                            <Header />
                            <HeaderCustomer />
                            <BillBodyLayerHeaderItems data={this.props.body} />
                            <Footer page='1' total='2' />
                        </div>
                        : <></>
                }

                {
                    this.props.body ? this.props.body.length > 4 ?
                        <div className={styles.page} >
                            <Header />
                            <BillBodyLayerLoop data={this.props.body} />
                            <FooterContent />
                            <Footer page='2' total='2' />
                        </div>
                        : <></> : <></>
                }

            </div>
        );
    }
}



function TaxInvoiceBill(props) {
    const [open, setOpen] = React.useState(false);
    const [loading, setLoading ]= React.useState(false);
    const [message, setMessage] = React.useState("");
    const [color, setColor] = React.useState("warning");
    const data = {
        open: open,
        message: message,
        color: color,
        loading: loading,
        progress: 0
    }

    const [TaxInvoiceBody, setTaxInvoiceBody] = useState([]);
    const [TaxInvoiceHeader, setTaxInvoiceHeader] = useState([]);
    const componentRef = useRef();
    const { off_id, bill_id } = useParams();
    const dispatch = useDispatch();
    useEffect(() => {
        const data = {
            company_id: off_id,
            Invoice_id: bill_id
        }
setLoading(true)
        dispatch(SelectTaxInvoice(data)).then(res => {

            setTaxInvoiceBody(res.payload.data.body)
            setTaxInvoiceHeader(res.payload.data.header)
            setLoading(false)
        })

    }, [bill_id, off_id, dispatch])







    return (
        <div >
                <Loading data={data} />
            <br />

            <ReactToPrint
                trigger={() => <center>
                    <h3 style={{ textAlign: 'center' }}> <b>ສະບາຍດີ TaxInvoice.la ຍິນດີໃຫ້ບໍລິການໃບເກັບເງິນແບບອອນລາຍ</b></h3>
                    <button className='btn btn-primary'>ພິມໃບເກັບເງິນ</button>
                </center>}
                content={() => componentRef.current}
            />
            <div style={{display:'none'}}>
            <ComponentToPrintBlock ref={componentRef} body={TaxInvoiceBody} header={TaxInvoiceHeader} />
            </div>
          
            <ComponentToPrint  body={TaxInvoiceBody} header={TaxInvoiceHeader} />
            <center>
                <h1><b><u>ສະຖານະການແຈ້ງອາກອນ</u></b></h1>
                <br />
                {
                    props.TAXI_INVOICE ?
                        props.TAXI_INVOICE.data.status[0].resp_MSG === "success" ?
                            <div>  <h4><b>ແຈ້ງອາກອນຊຳເລັດແລ້ວ...</b></h4>
                                <TaskAltIcon color='success' style={{
                                    fontSize: "200px"
                                }} /> </div> :
                            <div>
                                <h4><b>ກຳລັງດຳເນິນການແຈ້ງອາກອນ...</b></h4>
                                <PendingActionsIcon color='warning' style={{
                                    fontSize: "200px"
                                }} /> </div> : <></>
                }

            </center>
            <br />
            <br />
        </div>

    )

}


function mapStateToProps(state) {
    return {
        TAXI_INVOICE: state.Company.tax_invoice,

    }
}
export default connect(mapStateToProps)(TaxInvoiceBill)