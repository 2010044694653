import { USER_GET_AUTH, USER_LOGIN, USER_LOGIN_VERIFY } from "../Type/Type";


const UserLogin = (state = {}, action) => {
    switch (action.type) {
        case USER_LOGIN:
            return { ...state, UserLogin: action.payload }
        case USER_LOGIN_VERIFY:
            return { ...state, UserLogin: action.payload }
        case USER_GET_AUTH:
            return { ...state, UserLogin: action.payload }
        default:
            return state;
    }
};

export default UserLogin;
