import React, { Component } from 'react';

class QuideBook extends Component {
    render() {
        return (
            <div>
                Developing
            </div>
        );
    }
}

export default QuideBook;