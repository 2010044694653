
import React from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import { Card, Spinner } from "reactstrap";
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress, Typography } from '@mui/material';
import { Box, textAlign, width } from '@mui/system';
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

const Loading = ({ data }) => {
    const classes = useStyles();
    const open = data === undefined ? false : data.loading
    const progress = data === undefined ? 0 : data.progress
    return (
        <Backdrop className={classes.backdrop} open={open} >
            {
                progress > 0 ?

                    <div style={{ width: 200, height: 200 }}>
                        <CircularProgressbar
                            styles={buildStyles({
                                textColor: '#0564c8',
                                trailColor: '#d6d6d6',
                                backgroundColor: '#0564c8',
                                pathColor:'#0564c8'
                            })
                            }
                            value={progress} text={`${progress}%`} />
                    </div>

                    :

                    <Spinner animation="border" color="info" />

            }
        </Backdrop >
    );
}

export default Loading