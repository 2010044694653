import { Breadcrumbs, Chip } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

const BreadcrumbFunction = (props) => {

    return (
        <div>
            <Breadcrumbs aria-label="breadcrumb" separator={<NavigateNextIcon fontSize="small" />}>
                {props.data.map((item, index) => {
                    return (
                        <Link key={index} underline="hover" color="inherit" to={item.link} >
                            <Chip style={{ cursor: "pointer" }} variant="outlined" label={<b>{item.title}</b>} />

                        </Link>
                    )
                })}
            </Breadcrumbs>
            <br />
        </div>
    );
};

export default BreadcrumbFunction;