import moment from 'moment'
import React from 'react'
import { connect } from 'react-redux'
import styles from './../../../Res/css/TaxInvoice.module.css'




 function Header(props) {

    const b64 = props.TAXI_INVOICE ?  props.TAXI_INVOICE.data.office[0].com_logo != null ? Buffer.from(props.TAXI_INVOICE ?  props.TAXI_INVOICE.data.office[0].com_logo:'').toString('base64'):null:null;
    return (
        <div>
            <div className={styles.header}>
                <br />
                <br />

                <center  >
                    <h3><b>ໃບເກັບເງິນ/TAX INVOICE</b></h3>
                    <h6>(ອາກອນມຸນຄ່າເພີ່ມ VAT)</h6>
                </center>
            </div>
            <div className="row">
             


    
                <div className="col-6">
                    <br />
                    <div style={{ textAlign: 'left', marginTop: "15px" , marginLeft:"50px" }} >
                    {
                        props.TAXI_INVOICE ?  props.TAXI_INVOICE.data.office[0].com_logo === null ?   
                         <></>:<>
                    
                         <img alt="img" width="80" height="80"  src={'data:image/jpeg;base64,' + (b64)} />
                         </>:<></>
                    }
                 
                </div>
                </div>
                <div className="col-6">
                    <br />
                    <div >
                        <h6  style={{ textAlign: 'left',marginLeft:"180px", marginTop: "25px" }}>
                            ເລກທີ: {props.TAXI_INVOICE ?  props.TAXI_INVOICE.data.header[0].certify:0}
                        </h6>
                        <h6  style={{ textAlign: 'left', marginLeft:"122px", }}>
                            ເອກະສານອ້າງອິງ: {props.TAXI_INVOICE ?  props.TAXI_INVOICE.data.header[0].Referno:0}
                        </h6>
                        <h6  style={{ textAlign: 'left', marginLeft:"185px", }}>
                            ວັນທີ: {moment( props.TAXI_INVOICE ?  props.TAXI_INVOICE.data.header[0].date_work:0).format("DD/MM/YYYY")}
                        </h6>
                    </div>
                </div>
            </div>           
        </div>
    )
}


function mapStateToProps(state) {
    return {
        TAXI_INVOICE: state.Company.tax_invoice,
  
    }
  }
  export default connect(mapStateToProps)(Header)