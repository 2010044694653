import MenuIcon from '@mui/icons-material/Menu';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { Link } from 'react-router-dom';

export default function AppBarHeader() {



    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static">
                <Toolbar>
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"

                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                        Taxinvoice.la
                    </Typography>



                   
                    &nbsp;&nbsp;&nbsp;
                    <Link to={"/login"}>
                        <Button variant='contained' color="success">ເຂົ້າລະບົບ</Button>
                    </Link>
                   
                </Toolbar>
            </AppBar>
        </Box >
    );
}
