import React, { Component } from 'react';
import company from '../../Res/images/company.pdf'
class ListOfIvoiceSubmit extends Component {
    render() {
        return (
            <div className='container' >
              <iframe src={company} style={{height:"1700px", width:"100%" , borderRadius:"10px" , backgroundColor:'white'}}></iframe>
            </div>
        );
    }
}

export default ListOfIvoiceSubmit;