import React from 'react'
import { connect } from 'react-redux'
import styles from './../../../Res/css/TaxInvoice.module.css'
import CompanyLogo from '../../../Res/images/company.png'
import moment from 'moment'
function Footer(props) {
    return (
        <div className={styles.footer}>
            <hr style={{ margin: "0px", height: '3px', borderRadius: "100px", opacity: "100" }} />
            <div className="row" >
                <div className="col-6">
                    <div style={{ marginTop: "10px", marginLeft: "30px" }}>
                        ໃບອານຸຍາດນຳໃຊ້ໃບເກັບເງິນສະເພາະ
                    </div>
                    <div style={{ marginLeft: "30px" }}>
                        ເລກທີ: {props.TAXI_INVOICE ? props.TAXI_INVOICE.data.office[0].Licen_Tax_Office : 0}
                    </div>
                </div>
                <div className="col-6">
                    <div className="row">
                        <div className="col-5" style={{padding:"0px"}}>
                            <div style={{ textAlign: 'right' , marginTop: "10px"}}>
                                <img src={CompanyLogo} alt="TSD" width="60" height="60" />
                                <br />

                            </div>

                        </div>
                        <div className="col-7" style={{padding:"0px"}}>
                            <center>
                                <div style={{ marginTop: "10px", marginRight: "20px" }}>
                                    ພັດທະນາໂດຍ
                                </div>
                                <div style={{ marginRight: "20px", padding:"0px" }}>
                                    ບໍລິສັດ ທີເອັດສດີ ຈຳກັດຜູ້ດຽວ
                                </div>
                                <div>
                                    Page {props.page} of {props.total}
                                </div>
                            </center>
                        </div>
                    </div>

                </div>
            </div>


        </div>
    )
}

function mapStateToProps(state) {
    return {
        TAXI_INVOICE: state.Company.tax_invoice,

    }
}
export default connect(mapStateToProps)(Footer)