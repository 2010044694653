import { lazy, Suspense, useEffect } from 'react';
import { Route, Routes, useNavigate } from "react-router-dom";
import DashboardLayoutLayout from '../Layout/DashboardLayout.layout';

import ResetPassword from '../Pages/ResetPassword';
import Thanks from '../Pages/Thanks';
import './../Res/css/style.css';

import RequireAuthAdministrator from '../Middleware/AuthenAdmin';
import QuideBook from '../Pages/QuideBook/QuideBook';
import CreateInvoice from '../Pages/TaxInvoice/CreateInvoice';
import InvoiceList from '../Pages/TaxInvoice/InvoiceList';
import ListOfIvoiceSubmit from '../Pages/TaxInvoice/ListOfIvoiceSubmit';
import DashboardAdmin from '../Pages/Dashboard/DashboardAdmin';
import TaxInvoiceBill from '../Pages/TaxInvoice/TaxInvoiceBill';
import UpdateInvoice from '../Pages/TaxInvoice/UpdateInvoice';
import Developing from '../Pages/TaxInvoice/Developing';

const PageNotFound = lazy(() => import('../Pages/Page_not_found'));
const Login = lazy(() => import('../Pages/Login'));
const ForgotPassword = lazy(() => import('../Pages/ForgetPassword'));


function App() {
    const navigate = useNavigate();
    useEffect(() => {
        function CheckLogin() {
            if (localStorage.getItem("TaxToken") === null) {
                navigate('/')
            }
        }
        CheckLogin()
    }, [])
    return (
        <Suspense fallback={<div>loading.....</div>}>

            <Routes>

                <Route path="/" >
                    <Route index path="/" element={<Login />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/forgot-password" element={<ForgotPassword />} />
                    <Route path="/reset-password/:reset_token" element={<ResetPassword />} />
                    <Route path="/thanks" element={<Thanks />} />
                    <Route path="/test" element={<CreateInvoice />} />
                    <Route path="/dashboard/bill-tax-invoice-online/:off_id/:bill_id"
                        element={<TaxInvoiceBill />}
                    />

                    {/* This route use for audit controller */}
                    {/* This route use for audit is a private routes. */}
                    <Route path="/dashboard" element={<DashboardLayoutLayout />} >
                        <Route path="/dashboard" element={
                            <RequireAuthAdministrator>
                                <DashboardAdmin />
                            </RequireAuthAdministrator>
                        } />

                        <Route path="/dashboard/create-tax-invoice" element={
                            <RequireAuthAdministrator>
                                <CreateInvoice />
                            </RequireAuthAdministrator>
                        } />
                        {/* <Route path="/dashboard/bill-tax-invoice/:bill_id" element={
                            <RequireAuthAdministrator>
                                <BIllTaxInvoice />
                            </RequireAuthAdministrator>
                        } /> */}

                        <Route path="/dashboard/bill-tax-invoice/:off_id/:bill_id" element={
                            <RequireAuthAdministrator>
                                <TaxInvoiceBill />
                            </RequireAuthAdministrator>
                        } />

                        <Route path="/dashboard/update-tax-invoice/:off_id/:bill_id" element={
                            <RequireAuthAdministrator>
                                <Developing />
                            </RequireAuthAdministrator>
                        } />

                        <Route path="/dashboard/list-tax-invoice" element={
                            <RequireAuthAdministrator>
                                <InvoiceList />
                            </RequireAuthAdministrator>
                        } />
                        <Route path="/dashboard/company" element={
                            <RequireAuthAdministrator>
                                <ListOfIvoiceSubmit />
                            </RequireAuthAdministrator>
                        } />
                        <Route path="/dashboard/guide-book" element={
                            <RequireAuthAdministrator>
                                <QuideBook />
                            </RequireAuthAdministrator>
                        } />

                        <Route path="*" element={<PageNotFound />} />
                    </Route>









                    <Route path="*" element={<PageNotFound />} />
                </Route>

            </Routes>

        </Suspense >
    );
};

export default App;