
import CloseIcon from "@mui/icons-material/Close";
import { AppBar, Dialog, DialogContent, IconButton, Slide, Toolbar, Typography } from '@mui/material';
import moment from "moment";
import React, { useEffect, useRef, useState } from 'react';
import { NumericFormat } from 'react-number-format';
import { connect, useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { Card } from 'reactstrap';
import swal from 'sweetalert';
import useForceUpdate from 'use-force-update';
import {
    CreateAccountGroup, CreateTaxInvoiceBill, SelectAccountAssetGroupById, SelectAccountCustomerGroupById, selectAccountingCodeDetails, SelectAssetAccounting,
    SelectAssetAccountingSearchName,
    selectCateInvoice,
    selectCateTaxInvoice,
    SelectCustomerAccounting, SelectCustomerAccountingSearchName, SelectDistrict, SelectProvince, updateAccountGroup
} from '../../API/Action/Company.action';
import { exceptThisSymbols } from "../../API/Type/Type";
import AlertFunction from "../../Components/AlertFunction";
import Loading from "../../Components/Loading";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});



const accountingLaoFormat = (inputNumber) => {

    let number = (Number(inputNumber)).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    var NumberCustom = "";
    number.split('').map(item => {
        if (item === ",") {
            NumberCustom += "."
        } else if (item === ".") {
            NumberCustom += ","
        } else {
            NumberCustom += item;
        }
    })

    return NumberCustom.toString();
}

const accountingEngFormat = (inputNumber) => {
    let number = inputNumber.toString();
    var NumberCustom = "";
    number.split('').map(item => {
        if (item === ",") {
            NumberCustom += "."
        } else if (item === ".") {
        } else {
            NumberCustom += item;
        }
    })
    return NumberCustom.toString();
}



function CreateInvoice(props) {
    const [TaxInvoice, SetTaxInvoice] = useState([
        {
            acc_code: "",
            acc_name: "",
            acc_unit: "",
            acc_qty: 1,
            acc_price: 0.00,
            acc_check: false
        }
    ])








    const [acc_income, setAcc_income] = useState(false)

    const [AcAS_code, setAcAS_code] = useState("")
    const [AcAS_name_lao, setAcAS_name_lao] = useState("")
    const [AcAS_name_eng, setAcAS_name_eng] = useState("")
    const [AcAS_name_ch, setAcAS_name_ch] = useState("")



    const forceUpdate = useForceUpdate();
    const navigate = useNavigate();
    const [ac_code, setAc_code] = useState("")
    const [acc_customer_code, setAcc_customer_code] = useState("")
    const [ac_name_lao, setAc_name_lao] = useState("")
    const [ac_name_eng, setAc_name_eng] = useState("")
    const [ac_name_ch, setAc_name_ch] = useState("")
    const [ac_unit, setAc_unit] = useState("")

    const [customer_name, setCustomerName] = useState("")
    const [customer_tel, setCustomer_tel] = useState("")
    const [bank_account, setBank_account] = useState("")
    const [bank_name, setBank_name] = useState("")
    const [province, setProvince] = useState("0")
    const [district, setDistrict] = useState("0")
    const [village, setVillage] = useState("")



    const [H1_nm, setH1_nm] = useState("")
    const [H1, setH1] = useState("")

    const [UpdateStatus, setUpdateStatus] = useState(false)
    const [acc_code_status, setAcc_code_status] = useState(false)

    const [Rate, setRate] = useState(1)
    const [RateMin, setRateMin] = useState(0)
    const [Currency, setCurrency] = useState("LAK")
    const [Remark, setRemark] = useState("")
    const [refId, setRefId] = useState("")
    const [invoiceDate, setInvoiceDate] = useState(moment(new Date()).format('YYYY-MM-DD'))
    const [vat_name, setVat_name] = useState("")

    const [customer_tax_id, setCustomer_tax_id] = useState("")
    const [open, setOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [message, setMessage] = React.useState("");
    const [color, setColor] = React.useState("warning");

    const [TaxInOut, setTaxInOut] = React.useState("");
    const [TaxCateInvoice, setTaxCateInvoice] = React.useState("7");
    const [TaxCateInvoiceOut, setTaxCateInvoiceOut] = React.useState("7");
    const [CheckVat, setCheckVat] = React.useState("");

    const [TaxCate, setTaxCate] = React.useState("");


    const [CheckAccount, setCheckAccount] = React.useState("");

    const data = {
        open: open,
        message: message,
        color: color,
        loading: loading,
        progress: 0
    }


    const ListBreadcrumb = [
        {
            link: '/dashboard',
            title: 'ໜ້າຫຼັກຂອງລະບົບ'
        },
        {
            link: '/dashboard/create-tax-invoice',
            title: 'ອອກໃບເກັບເງິນ'
        },

    ]


    const limitUpdateValueCus = [121, 1211, 1212, 1213, 141, 142, 143, 145, 146, 148]
    const limitUpdateValueAs = [70, 701, 702, 704, 705, 707, 708, 71, 711, 712, 715, 717, 718, 74, 741, 748, 75, 751, 752, 753, 754, 755, 756, 757, 758]
    const customer_acc = useRef(null);
    const asset_acc = useRef(null);
    const dispatch = useDispatch();

    useEffect(() => {
        setLoading(true)
        setTaxInOut("1")
        setTaxCate("1")
        dispatch(SelectProvince())
        dispatch(selectCateInvoice())
        dispatch(selectCateTaxInvoice({ tsd_id: "1" })).then(res => {
            setTaxCateInvoice(7)
            setCheckVat(1)
            setTaxCateInvoiceOut("107")
            setLoading(false)
        })
        setVat_name("ອັດຕາ ອ.ມ.ພ: 7%")
    }, [])















    function patternMatch({ input, template }) {
        try {
            let j = 0;
            let plaintext = "";
            let countj = 0;
            while (j < template.length) {
                if (countj > input.length - 1) {
                    template = template.substring(0, j);
                    break;
                }

                if (template[j] == input[j]) {
                    j++;
                    countj++;
                    continue;
                }

                if (template[j] == "x") {
                    template =
                        template.substring(0, j) + input[countj] + template.substring(j + 1);
                    plaintext = plaintext + input[countj];
                    countj++;
                }
                j++;
            }

            return template;
        } catch {
            return "";
        }
    }

















    const FunctionSelectDistrict = (e, id) => {

        dispatch(SelectDistrict({ "province_name": e.target.value }))
        setProvince(e.target.value)
    }



    const [customerOpen, setCustomerOpen] = React.useState(false);
    const [assetOpen, setAssetOpen] = React.useState(false);


    const OpenCustomerAccount = () => {
        setCustomerOpen(true)
        funcCusClear()
        setLoading(true)
        dispatch(SelectCustomerAccounting({ company_id: localStorage.getItem("off_id") })).then(res => {
            setLoading(false)
        });

    }

    const OpenAssetAccount = (e, index) => {
        setTaxIndex(index)
        setAssetOpen(true)
        funcAssClear()
        setLoading(true)
        dispatch(SelectAssetAccounting({ company_id: localStorage.getItem("off_id") })).then(res => [
            setLoading(false)
        ]);

    }




    const scrollTopCus = (row) => {
        setAc_code(row.AC_CODE)
        dispatch(SelectDistrict({ "province_name": row.Ackhaeng_c }))
        setAc_name_lao(row.Name_L)
        setAc_name_eng(row.Name_E)
        setAc_name_ch(row.Name_Ch)
        setVillage(row.AcBan_c)
        setDistrict(row.AcMeung_c)
        setProvince(row.Ackhaeng_c)
        setCustomerName(row.AcName_c)
        setCustomer_tel(row.AcTel_c)
        setBank_account(row.BankCode_c)
        setBank_name(row.Bankname_c)
        setCustomer_tax_id(row.AcText_c)
        setH1(row.H1)
        setH1_nm(row.H1_nm)
        setUpdateStatus(true)
        setAcc_code_status(true)
        customer_acc.current.scrollIntoView()
    }


    const scrollTopAss = (row) => {
        setAcc_code_status(true)
        setAcAS_code(row.AC_CODE)
        setAcAS_name_lao(row.Name_L)
        setAcAS_name_eng(row.Name_E)
        setAcAS_name_ch(row.Name_Ch)
        setAc_unit(row.AcUnit_c)

        setUpdateStatus(true)
        asset_acc.current.scrollIntoView();
    }

    const funcAssClear = () => {
        setAcAS_code("")
        setAcAS_name_lao("")
        setAcAS_name_eng("")
        setAcAS_name_ch("")
        setAc_unit("")
        setUpdateStatus(false)
        setAcc_code_status(false)

    }
    const funcCusClear = () => {
        setAc_code("")
        setAc_name_lao("")
        setAc_name_eng("")
        setAc_name_ch("")
        setVillage("")
        setDistrict("")
        setProvince("")
        setCustomerName("")
        setCustomer_tel("")
        setBank_account("")
        setBank_name("")
        setAc_unit("")
        setUpdateStatus(false)
        setAcc_code_status(false)

    }











    const handleClose = () => {
        setAssetOpen(false)
        setCustomerOpen(false)
        setOpen(false)
    };



    const funcSelectAccountGroup = (e) => {
        exceptThisSymbols.includes(e.key) && e.preventDefault()

        const data = {
            company_id: localStorage.getItem("off_id"),
            ac_code: ac_code
        }
        dispatch(SelectAccountCustomerGroupById(data)).then(res => {
            if (res.payload.data.length > 0) {
                setAc_name_lao(res.payload.data[0].Name_L)
                setAc_name_eng(res.payload.data[0].Name_E)
                setAc_name_ch(res.payload.data[0].Name_Ch)
                setH1_nm(res.payload.data[0].H1_nm)
                setH1(res.payload.data[0].H1)
            } else {
                setAc_name_lao("")
                setAc_name_eng("")
                setAc_name_ch("")
                setH1_nm("")
                setH1("")
                setMessage("ເລກບັນຊີນິບໍ່ມີໃນລະບົບ");
                setColor("warning");
                setOpen(true);
                setLoading(false)
            }
        })

    }


    const funcSelectAccountGroupCustomer = (e, value) => {
        exceptThisSymbols.includes(e.key) && e.preventDefault()

        if (e.key === "Enter") {


            const data = {
                company_id: localStorage.getItem("off_id"),
                ac_code: e.target.value
            }
            dispatch(SelectAccountCustomerGroupById(data)).then(res => {
                if (res.payload.data.length > 0) {
                    dispatch(SelectDistrict({ "province_name": res.payload.data[0].Ackhaeng_c }))
                    setAc_name_lao(res.payload.data[0].Name_L)
                    setAc_name_eng(res.payload.data[0].Name_E)
                    setAc_name_ch(res.payload.data[0].Name_Ch)
                    setH1_nm(res.payload.data[0].H1_nm)
                    setH1(res.payload.data[0].H1)
                    setCustomerName(res.payload.data[0].AcName_c)
                    setVillage(res.payload.data[0].AcBan_c)
                    setDistrict(res.payload.data[0].AcMeung_c)
                    setProvince(res.payload.data[0].Ackhaeng_c)
                    setCustomer_tax_id(res.payload.data[0].AcText_c)
                    setBank_account(res.payload.data[0].BankCode_c)
                    setBank_name(res.payload.data[0].Bankname_c)


                } else {
                    setAc_name_lao("")
                    setAc_name_eng("")
                    setAc_name_ch("")
                    setH1_nm("")
                    setH1("")
                    setCustomerName("")
                    setVillage("")
                    setDistrict("")
                    setProvince("")
                    setCustomer_tax_id("")
                    setBank_account("")
                    setBank_name("")

                    setMessage("ເລກບັນຊີນິບໍ່ມີໃນລະບົບ");
                    setColor("warning");
                    setOpen(true);
                    setLoading(false)
                }
            })
        }
    }

    const [TaxIndex, setTaxIndex] = useState()
    const funcSelectAccountGroupCustomerFindInCome = (value) => {
        TaxInvoice[TaxIndex].acc_code = value.AC_CODE;
        forceUpdate();
        TaxInvoice[TaxIndex].acc_name = value.Name_L;
        forceUpdate();
        TaxInvoice[TaxIndex].acc_unit = value.AcUnit_c;
        forceUpdate();
        TaxInvoice[TaxIndex].acc_check = true;
        forceUpdate();

        setAssetOpen(false)
    }


    const funcSelectAccountGroupCustomerFind = (value) => {
        setAc_code(value)
        setAcc_customer_code(value)
        const data = {
            company_id: localStorage.getItem("off_id"),
            ac_code: value
        }
        dispatch(SelectAccountCustomerGroupById(data)).then(res => {
            if (res.payload.data.length > 0) {
                dispatch(SelectDistrict({ "province_name": res.payload.data[0].Ackhaeng_c }))
                setAc_name_lao(res.payload.data[0].Name_L)
                setAc_name_eng(res.payload.data[0].Name_E)
                setAc_name_ch(res.payload.data[0].Name_Ch)
                setH1_nm(res.payload.data[0].H1_nm)
                setH1(res.payload.data[0].H1)
                setCustomerName(res.payload.data[0].AcName_c)
                setVillage(res.payload.data[0].AcBan_c)
                setDistrict(res.payload.data[0].AcMeung_c)
                setProvince(res.payload.data[0].Ackhaeng_c)
                setCustomer_tax_id(res.payload.data[0].AcText_c)
                setBank_account(res.payload.data[0].BankCode_c)
                setBank_name(res.payload.data[0].Bankname_c)


            } else {
                setAc_name_lao("")
                setAc_name_eng("")
                setAc_name_ch("")
                setH1_nm("")
                setH1("")
                setCustomerName("")
                setVillage("")
                setDistrict("")
                setProvince("")
                setCustomer_tax_id("")
                setBank_account("")
                setBank_name("")

                setMessage("ເລກບັນຊີນິບໍ່ມີໃນລະບົບ");
                setColor("warning");
                setOpen(true);
                setLoading(false)
            }
        })
        setCustomerOpen(false)
    }





    const funcSelectAccountAsGroup = (e) => {
        exceptThisSymbols.includes(e.key) && e.preventDefault()
        if (e.key === "Enter") {
            const data = {
                company_id: localStorage.getItem("off_id"),
                ac_code: AcAS_code
            }
            dispatch(SelectAccountAssetGroupById(data)).then(res => {
                if (res.payload.data.length > 0) {
                    setAcAS_name_lao(res.payload.data[0].Name_L)
                    setAcAS_name_eng(res.payload.data[0].Name_E)
                    setAcAS_name_ch(res.payload.data[0].Name_Ch)
                    setH1_nm(res.payload.data[0].H1_nm)
                    setH1(res.payload.data[0].H1)
                } else {
                    setMessage("ເລກບັນຊີນິບໍ່ມີໃນລະບົບ");
                    setColor("warning");
                    setOpen(true);
                    setLoading(false)
                }
            })
        }
    }


    const funcCreateAccountGroup = (e) => {
        e.preventDefault();
        setLoading(true)
        if (ac_code === "") {
            setMessage("ກະລຸນາເພີ່ມ ເລກບັນຊີ");
            setColor("warning");
            setOpen(true);
            setLoading(false)
        } else if (customer_name === "") {
            setMessage("ກະລຸນາເພີ່ມ ຊື່ບັນຊີລູກຄ້າ");
            setColor("warning");
            setOpen(true);
            setLoading(false)
        } else if (customer_tax_id === "" || customer_tax_id.length !== 12) {
            setMessage("ກະລຸນາເພີ່ມ ເລກຜູ້ເສຍອາກອນ");
            setColor("warning");
            setOpen(true);
            setLoading(false)
        } else if (customer_tel === "") {
            setMessage("ກະລຸນາເພີ່ມ ເບີໂທລະສັບ");
            setColor("warning");
            setOpen(true);
            setLoading(false)

        } else {
            const data = {
                "AC_CODE": ac_code,
                "AC_CODE2": ac_code,
                "Name_L": ac_name_lao,
                "Name_E": ac_name_eng,
                "Name_Ch": ac_name_ch,
                "type": null,
                "type_id": null,
                "rem": null,
                "rem_id": null,
                "chk_id": null,
                "AC_GrpL": null,
                "AC_GrpE": null,
                "Acc_Type": "ບັນຊີຍ່ອຍ (D)",
                "Acc_TypeE": "Detail Account",
                "section": null,
                "section_ID": null,
                "loop": null,
                "loop_ID": null,
                "other": null,
                "Print_status": 0,
                "lst_usr": "ຜູ້ພັດທະນາໂປແກມ",
                "Get_date": null,
                "pc_nm": null,
                "ST": 1,
                "Remark": null,
                "Company": localStorage.getItem("off_id"),
                "Sections": null,
                "H1": H1,
                "H2": null,
                "H3": null,
                "H4": null,
                "H1_nm": H1_nm,
                "H2_nm": null,
                "H3_nm": null,
                "AcName_c": customer_name,
                "AcBan_c": village,
                "AcMeung_c": district,
                "Ackhaeng_c": province,
                "AcTel_c": customer_tel,
                "AcText_c": customer_tax_id,
                "AcUnit_c": ac_unit,
                "BankCode_c": bank_account,
                "Bankname_c": bank_name,
                "K_nm": "===",
                "K_id": "0"
            }
            dispatch(CreateAccountGroup(data)).then(res => {
                if (res.payload.success === "success") {
                    funcCusClear();
                    dispatch(SelectCustomerAccounting({ company_id: localStorage.getItem("off_id") }));
                    setMessage("ບັນທັນຂໍມູນສຳເລັດແລ້ວ");
                    setColor("success");
                    setOpen(true);
                    setLoading(false)
                    setUpdateStatus(false)
                } else {
                    setMessage(res.payload.message);
                    setColor("warning");
                    setOpen(true);
                    setLoading(false)
                }
            })
        }
    }
    const funcUpdateAccountGroup = (e) => {
        e.preventDefault();
        setLoading(true)
        if (ac_code === "") {
            setMessage("ກະລຸນາເພີ່ມ ເລກບັນຊີ");
            setColor("warning");
            setOpen(true);
            setLoading(false)
        } else if (customer_name === "") {
            setMessage("ກະລຸນາເພີ່ມ ຊື່ບັນຊີລູກຄ້າ");
            setColor("warning");
            setOpen(true);
            setLoading(false)
        } else if (customer_tax_id === "" || customer_tax_id.length !== 12) {
            setMessage("ກະລຸນາເພີ່ມ ເລກຜູ້ເສຍອາກອນ");
            setColor("warning");
            setOpen(true);
            setLoading(false)
        } else if (customer_tel === "") {
            setMessage("ກະລຸນາເພີ່ມ ເບີໂທລະສັບ");
            setColor("warning");
            setOpen(true);
            setLoading(false)

        } else {
            const data = {
                "AC_CODE": ac_code,
                "AC_CODE2": ac_code,
                "Name_L": ac_name_lao,
                "Name_E": ac_name_eng,
                "Name_Ch": ac_name_ch,
                "type": null,
                "type_id": null,
                "rem": null,
                "rem_id": null,
                "chk_id": null,
                "AC_GrpL": null,
                "AC_GrpE": null,
                "Acc_Type": "ບັນຊີຍ່ອຍ (D)",
                "Acc_TypeE": "Detail Account",
                "section": null,
                "section_ID": null,
                "loop": null,
                "loop_ID": null,
                "other": null,
                "Print_status": 0,
                "lst_usr": "ຜູ້ພັດທະນາໂປແກມ",
                "Get_date": null,
                "pc_nm": null,
                "ST": 1,
                "Remark": null,
                "Company": localStorage.getItem("off_id"),
                "Sections": null,
                "H1": H1,
                "H2": null,
                "H3": null,
                "H4": null,
                "H1_nm": H1_nm,
                "H2_nm": null,
                "H3_nm": null,
                "AcName_c": customer_name,
                "AcBan_c": village,
                "AcMeung_c": district,
                "Ackhaeng_c": province,
                "AcTel_c": customer_tel,
                "AcText_c": customer_tax_id,
                "AcUnit_c": ac_unit,
                "BankCode_c": bank_account,
                "Bankname_c": bank_name,
                "K_nm": "===",
                "K_id": "0"
            }
            dispatch(updateAccountGroup(data)).then(res => {
                if (res.payload.success === "success") {
                    funcCusClear();
                    dispatch(SelectCustomerAccounting({ company_id: localStorage.getItem("off_id") }));
                    setMessage("ບັນທັນຂໍມູນສຳເລັດແລ້ວ");
                    setColor("success");
                    setOpen(true);
                    setLoading(false)
                    setUpdateStatus(false)
                } else {
                    setMessage(res.payload.message);
                    setColor("warning");
                    setOpen(true);
                    setLoading(false)
                }
            })

        }

    }


    const funcCreateAccountAsGroup = (e) => {
        e.preventDefault();
        setLoading(true)
        if (ac_code === "") {
            setMessage("ກະລຸນາເພີ່ມ ເລກບັນຊີ");
            setColor("warning");
            setOpen(true);
            setLoading(false)
        } else if (ac_unit === "") {
            setMessage("ກະລຸນາເພີ່ມ ຫົວໜ່ວຍ");
            setColor("warning");
            setOpen(true);
            setLoading(false)
        } else {
            const data = {
                "AC_CODE": AcAS_code,
                "AC_CODE2": AcAS_code,
                "Name_L": AcAS_name_lao,
                "Name_E": AcAS_name_eng,
                "Name_Ch": AcAS_name_ch,
                "type": null,
                "type_id": null,
                "rem": null,
                "rem_id": null,
                "chk_id": null,
                "AC_GrpL": null,
                "AC_GrpE": null,
                "Acc_Type": "ບັນຊີຍ່ອຍ (D)",
                "Acc_TypeE": "Detail Account",
                "section": null,
                "section_ID": null,
                "loop": null,
                "loop_ID": null,
                "other": null,
                "Print_status": 0,
                "lst_usr": "ຜູ້ພັດທະນາໂປແກມ",
                "Get_date": null,
                "pc_nm": null,
                "ST": 1,
                "Remark": null,
                "Company": localStorage.getItem("off_id"),
                "Sections": null,
                "H1": H1,
                "H2": null,
                "H3": null,
                "H4": null,
                "H1_nm": H1_nm,
                "H2_nm": null,
                "H3_nm": null,
                "AcName_c": null,
                "AcBan_c": null,
                "AcMeung_c": null,
                "Ackhaeng_c": null,
                "AcTel_c": null,
                "AcText_c": null,
                "AcUnit_c": ac_unit,
                "BankCode_c": null,
                "Bankname_c": null,
                "K_nm": "===",
                "K_id": "0"
            }
            dispatch(CreateAccountGroup(data)).then(res => {
                if (res.payload.success === "success") {
                    funcCusClear();
                    dispatch(SelectAssetAccounting({ company_id: localStorage.getItem("off_id") }));
                    setMessage("ບັນທັນຂໍມູນສຳເລັດແລ້ວ");
                    setColor("success");
                    setOpen(true);
                    setLoading(false)
                    setUpdateStatus(false)
                } else {
                    setMessage(res.payload.message);
                    setColor("warning");
                    setOpen(true);
                    setLoading(false)
                }
            })

        }
    }
    const funcUpdateAccountAsGroup = (e) => {
        e.preventDefault();
        setLoading(true)
        if (ac_code === "") {
            setMessage("ກະລຸນາເພີ່ມ ເລກບັນຊີ");
            setColor("warning");
            setOpen(true);
            setLoading(false)
        } else if (ac_unit === "") {
            setMessage("ກະລຸນາເພີ່ມ ຫົວໜ່ວຍ");
            setColor("warning");
            setOpen(true);
            setLoading(false)
        } else {
            const data = {
                "AC_CODE": AcAS_code,
                "AC_CODE2": AcAS_code,
                "Name_L": AcAS_name_lao,
                "Name_E": AcAS_name_eng,
                "Name_Ch": AcAS_name_ch,
                "type": null,
                "type_id": null,
                "rem": null,
                "rem_id": null,
                "chk_id": null,
                "AC_GrpL": null,
                "AC_GrpE": null,
                "Acc_Type": "ບັນຊີຍ່ອຍ (D)",
                "Acc_TypeE": "Detail Account",
                "section": null,
                "section_ID": null,
                "loop": null,
                "loop_ID": null,
                "other": null,
                "Print_status": 0,
                "lst_usr": "ຜູ້ພັດທະນາໂປແກມ",
                "Get_date": null,
                "pc_nm": null,
                "ST": 1,
                "Remark": null,
                "Company": localStorage.getItem("off_id"),
                "Sections": null,
                "H1": H1,
                "H2": null,
                "H3": null,
                "H4": null,
                "H1_nm": H1_nm,
                "H2_nm": null,
                "H3_nm": null,
                "AcName_c": null,
                "AcBan_c": null,
                "AcMeung_c": null,
                "Ackhaeng_c": null,
                "AcTel_c": null,
                "AcText_c": null,
                "AcUnit_c": ac_unit,
                "BankCode_c": null,
                "Bankname_c": null,
                "K_nm": "===",
                "K_id": "0"
            }
            dispatch(updateAccountGroup(data)).then(res => {
                if (res.payload.success === "success") {
                    funcCusClear();
                    dispatch(SelectAssetAccounting({ company_id: localStorage.getItem("off_id") }));
                    setMessage("ບັນທັນຂໍມູນສຳເລັດແລ້ວ");
                    setColor("success");
                    setOpen(true);
                    setLoading(false)
                    setUpdateStatus(false)
                } else {
                    setMessage(res.payload.message);
                    setColor("warning");
                    setOpen(true);
                    setLoading(false)
                }
            })
        }
    }



    const selectCateTaxIn = (e) => {
        setTaxCate(e.target.value)
        dispatch(selectCateTaxInvoice({ tsd_id: e.target.value }));
    }




    const onClose = () => {
        setOpen(false)
    }



    const funcRemoveTaxInvoice = (e, index) => {
        if (index > 0) {
            TaxInvoice.splice(index, 1)
            forceUpdate();
        }

    }





    const UpdateAccountingName = (e, index) => {
        TaxInvoice[index].acc_name = e.target.value;
        forceUpdate();
        funcAutoAddNewItem();
    }


    const UpdateAccountingUnitName = (e, index) => {
        TaxInvoice[index].acc_unit = e.target.value;
        forceUpdate();
        funcAutoAddNewItem();
    }


    const funcAutoAddNewItem = () => {

        if (TaxInvoice[TaxInvoice.length - 1].acc_code !== "" && TaxInvoice[TaxInvoice.length - 1].acc_name && TaxInvoice[TaxInvoice.length - 1].acc_price !== "" && TaxInvoice[TaxInvoice.length - 1].acc_unit !== "") {
            const TaxInvoiceList = {
                acc_code: "",
                acc_name: "",
                acc_unit: "",
                acc_qty: 1,
                acc_price: 0.00,

            }

            TaxInvoice.push(TaxInvoiceList);
            forceUpdate();
        }
    }




    const FuncSelectAccountingCode = (e, index) => {
        TaxInvoice[index].acc_code = e.target.value;
        forceUpdate();
        // const data = {
        //     Company: localStorage.getItem("off_id"),
        //     AC_CODE: e.target.value
        // }
        // selectAccountingCodeDetails(data).then(res => {
        //     if (res.payload.data.length > 0) {
        //         TaxInvoice[index].acc_name = res.payload.data[0].Name_L;
        //         forceUpdate();
        //         TaxInvoice[index].acc_unit = res.payload.data[0].AcUnit_c === null ? "ຫົວໜ່ວຍ" : res.payload.data[0].AcUnit_c;
        //         forceUpdate();
        //         TaxInvoice[index].acc_check = true;
        //         forceUpdate()
        //     } else {
        //         TaxInvoice[index].acc_name = "";
        //         forceUpdate();
        //         TaxInvoice[index].acc_unit = "";
        //         forceUpdate();
        //         TaxInvoice[index].acc_check = false;
        //         forceUpdate();
        //     }

        // })
        funcAutoAddNewItem();
    }

    const functionSelectCustomerAccount = (e) => {
        if (e.key === "Enter") {
            setLoading(true)
            const data = {
                company_id: localStorage.getItem("off_id"),
                ac_code: e.target.value
            }
            dispatch(SelectCustomerAccountingSearchName(data)).then(res => {
                setLoading(false)
            })
        }
    }
    const functionSelectAssetAccount = (e) => {
        if (e.key === "Enter") {
            setLoading(true)
            const data = {
                company_id: localStorage.getItem("off_id"),
                ac_code: e.target.value
            }
            dispatch(SelectAssetAccountingSearchName(data)).then(res => {
                setLoading(false)
            })
        }
    }
    const FuncSelectAccountingCodeDetails = (e, index) => {

        if (e.key === "Enter") {
            const data = {
                Company: localStorage.getItem("off_id"),
                AC_CODE: e.target.value
            }
            selectAccountingCodeDetails(data).then(res => {
                if (res.payload.data.length > 0) {
                    TaxInvoice[index].acc_name = res.payload.data[0].Name_L;
                    forceUpdate();
                    TaxInvoice[index].acc_unit = res.payload.data[0].AcUnit_c === null ? "ຫົວໜ່ວຍ" : res.payload.data[0].AcUnit_c;
                    forceUpdate();
                    TaxInvoice[index].acc_check = true;
                    forceUpdate();
                } else {
                    TaxInvoice[index].acc_name = "";
                    forceUpdate();
                    TaxInvoice[index].acc_unit = "";
                    forceUpdate();
                    TaxInvoice[index].acc_check = false;
                    forceUpdate();
                }

            })
        }

    }

    const UpdateProductQTy = (e, index) => {
        TaxInvoice[index].acc_qty = e.target.value;
        forceUpdate();
        funcAutoAddNewItem();
    }




    const UpdateProductPrice = (e, index) => {

        TaxInvoice[index].acc_price = e.target.value;
        forceUpdate();
        funcAutoAddNewItem();
    }


    const ChangeTaxInvoice = (e) => {
        setCheckVat(e.target.value)

        const index = e.target.selectedIndex;
        const el = e.target.childNodes[index]
        const option = el.getAttribute('id');
        const cal = option / 100;
        setTaxCateInvoice(cal)
        setTaxCateInvoiceOut(option)

        var indexVat = e.nativeEvent.target.selectedIndex;
        setVat_name(e.nativeEvent.target[indexVat].text)

    }



    const SaveTaxInvoice = (e) => {
        setLoading(true)
        if (TaxInvoice.length > 1 && TaxInvoice[TaxInvoice.length - 1].acc_code === "" && TaxInvoice[TaxInvoice.length - 1].acc_name === "") {
            TaxInvoice.splice(TaxInvoice.length - 1, 1);
            forceUpdate();
        }
        let check = true;
        TaxInvoice.forEach(item => {
            if (item.acc_code === "" || item.acc_name === "" || item.acc_unit === "" || Number(accountingEngFormat(item.acc_unit)) === 0 || Number(accountingEngFormat(item.acc_unit)) === 0) {
                check = false
            }
        })
        if (check === true) {


            if ((TaxInvoice[0].acc_code) === "" || (TaxInvoice[0].acc_name === "")) {
                setMessage("ກະລຸນາເພີ່ມ ລາຍການສິນຄ້າ");
                setColor("warning");
                setOpen(true);
                setLoading(false)
            } else
                if (Number(accountingEngFormat(TaxInvoice[0].acc_price)) === 0) {
                    setMessage("ກະລຸນາເພີ່ມ ລາຄາ");
                    setColor("warning");
                    setOpen(true);
                    setLoading(false)
                }
                else if (acc_customer_code === "") {
                    setMessage("ກະລຸນາເພີ່ມ ເລກບັນຊີລຸກຄ້າ");
                    setColor("warning");
                    setOpen(true);
                    setLoading(false)
                } else if (customer_name === "") {
                    setMessage("ກະລຸນາເພີ່ມ ຊື່ລູກຄ້າ");
                    setColor("warning");
                    setOpen(true);
                    setLoading(false)
                } else if (customer_tax_id === "" || customer_tax_id.length !== 12) {
                    setMessage("ກະລຸນາເພີ່ມ ເລກຜູ້ເສຍອາກອນ");
                    setColor("warning");
                    setOpen(true);
                    setLoading(false)
                } else if (customer_name === "") {
                    setMessage("ກະລຸນາເພີ່ມ ຊື່ລູກຄ້າ");
                    setColor("warning");
                    setOpen(true);
                    setLoading(false)
                } else {

                    if (TaxInvoice.length > 0) {

                        swal("ຕ້ອງການບັນທຶກໃບເກັບເງິນຫຼືບໍ່!", "ຖ້າທ່ານບັນທຶກໃບເກັບເງິນໄປແລ້ວຈະບໍາສາມາດຍົກເລີກໄດ້", "info", {
                            buttons: {
                                cancel: "ຍົກເລີກ",
                                success: {
                                    text: "ບັນທຶກຂໍ້ມູນ",
                                    value: "success",
                                },

                            },
                        })
                            .then((value) => {
                                switch (value) {
                                    case "success":
                                        if (TaxInvoice[TaxInvoice.length - 1].acc_code === "" && TaxInvoice[TaxInvoice.length - 1].acc_name === "") {
                                            TaxInvoice.splice(TaxInvoice.length - 1, 1);
                                            forceUpdate();
                                        }
                                        const TotalVat = Number(Number(Number(TaxInOut === "1" ?
                                            Number(
                                                TaxInvoice.reduce(function (_this, val) {
                                                    return _this + (accountingEngFormat(val.acc_price) * val.acc_qty);
                                                }, 0) * ((TaxCateInvoiceOut - 100) / 100)
                                            )
                                            : TaxInOut === "2" ?
                                                Number((
                                                    TaxInvoice.reduce(function (_this, val) {
                                                        return _this + (accountingEngFormat(val.acc_price) * val.acc_qty);
                                                    }, 0) * 100) / TaxCateInvoiceOut) * ((TaxCateInvoiceOut - 100) / 100) : 0))).toFixed(3);

                                        const Total = (Number(
                                            Number(
                                                Number(TaxInOut === "1" ?
                                                    Number(
                                                        TaxInvoice.reduce(function (_this, val) {
                                                            return _this + (accountingEngFormat(val.acc_price) * val.acc_qty);
                                                        }, 0) * ((TaxCateInvoiceOut - 100) / 100) + TaxInvoice.reduce(function (_this, val) {
                                                            return _this + (accountingEngFormat(val.acc_price) * val.acc_qty);
                                                        }, 0)
                                                    )
                                                    : TaxInOut === "2" ?
                                                        (Number((
                                                            TaxInvoice.reduce(function (_this, val) {
                                                                return _this + (accountingEngFormat(val.acc_price) * val.acc_qty);
                                                            }, 0) * 100) / TaxCateInvoiceOut) * ((TaxCateInvoiceOut - 100) / 100)) + ((
                                                                TaxInvoice.reduce(function (_this, val) {
                                                                    return _this + (accountingEngFormat(val.acc_price) * val.acc_qty);
                                                                }, 0) * 100) / TaxCateInvoiceOut) : 0))
                                        )).toFixed(3);




                                        const invoiceList = {
                                            totalVat: TotalVat,
                                            total: Total,
                                            TaxInvoice: TaxInvoice,
                                            refId: refId,
                                            invoiceDate: invoiceDate,
                                            CateTax: TaxInOut,
                                            Customer_acc: acc_customer_code,
                                            customer_name: customer_name,
                                            customer_tax_id: customer_tax_id,
                                            remark: Remark,
                                            currency: Currency,
                                            rate: Rate,
                                            cate_bill: TaxCate,
                                            cate_vat: TaxCateInvoice,
                                            customer_accounting_name: ac_name_lao,
                                            customer_bank_account_name: bank_name,
                                            customer_bank_account_number: bank_account,
                                            province: province,
                                            district: district,
                                            village: village,
                                            office_id: localStorage.getItem('off_id'),
                                            license_tax: localStorage.getItem('Licen_Tax_Office'),
                                            vat_name: vat_name
                                        }

                                        dispatch(CreateTaxInvoiceBill(invoiceList)).then(res => {
                                            if (res.payload.success === "success") {
                                                setLoading(false)
                                                window.open(`/dashboard/bill-tax-invoice/${localStorage.getItem('off_id')}/${res.payload.taxInvoice}`)
                                                window.location.reload();
                                            }
                                        })
                                        break;
                                    default:
                                        setLoading(false)
                                        break;
                                }
                            });

                    }
                }

        } else {
            swal("ຂໍ້ມູນບໍ່ຖືກຕ້ອງ", "ກະລຸນາກອດສອບຂໍ້ມູນຄືນໃໝ່!", "warning");
            setLoading(false)
        }
    }


    const funcSelectCurrency = (e) => {
        setCurrency(e.target.value)
        if (e.target.value === "LAK") {
            setRate(1)
        } else if (e.target.value === "THB") {
            setRate(450)
            setRateMin(450)

        } else if (e.target.value === "USD") {
            setRate(16000)
            setRateMin(16000)
        }
    }

    const funcExchange = (e) => {
        setRate((e.target.value))
    }

    const funcExchangeKeyUp = (e) => {

        if (Currency === "LAK") {
            setRate(1)
        } else if (Currency === "THB") {
            if (Number(Rate) < RateMin) {
                setRate(RateMin)
            } else {
                setRate((e.target.value))
            }

        } else if (Currency === "USD") {
            if (Number(Rate) < RateMin) {
                setRate(RateMin)
            } else {
                setRate((e.target.value))
            }
        }

    }


    return (
        <div >
            <AlertFunction data={data} onClose={onClose} />
            <Loading data={data} />
            {/* <BreadcrumbFunction data={ListBreadcrumb} /> */}
            {/* <h4 style={{ textAlign: 'center', marginTop: "-20px" }}><b>ອອກໃບເກັບເງິນ</b></h4> */}
            <Card style={{ padding: "15px", backgroundColor: "whitesmoke" }} >
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-12">
                                <label className="form-label"><b>ວັນທີ </b><b style={{ color: "red" }}> *</b></label>
                                <input type="date" className="form-control"
                                    onChange={(e) => setInvoiceDate(e.target.value)}
                                    value={invoiceDate}
                                    id="exampleFormControlInput1" placeholder="name@example.com" />

                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12">

                                <label className="form-label"><b>ປະເພດອາກອນ</b><b style={{ color: "red" }}> *</b></label>
                                <select className="form-select"
                                    value={TaxInOut}
                                    aria-label="Default select example"
                                    onChange={(e) => setTaxInOut(e.target.value)}>
                                    <option value="0" >...ເລືອກ...</option>
                                    <option value="1">ອາກອນ - ນອກ</option>
                                    <option value="2">ອາກອນ - ໃນ</option>
                                </select>

                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12">

                                <label className="form-label"><b>ເອກກະສານອ້າງອີງ</b></label>
                                <input type="text" className="form-control"
                                    value={refId}
                                    onChange={(e) => setRefId(e.target.value)}
                                    id="exampleFormControlInput1" placeholder="ເອກກະສານອ້າງອີງ" />

                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12">

                                <label className="form-label"><b>ເລກບັນຊີລຸກຄ້າ</b><b style={{ color: "red" }}> *</b></label>
                                <div className="input-group ">

                                    <input type="number"
                                        disabled={acc_code_status}
                                        onKeyDown={(e) => funcSelectAccountGroupCustomer(e)}
                                        value={acc_customer_code}
                                        onChange={(e) => setAcc_customer_code(e.target.value)}

                                        className="form-control"
                                        id="exampleFormControlInput1" placeholder="ເລກບັນຊີລຸກຄ້າ" />
                                    <button className="btn btn-primary" onClick={(e) => OpenCustomerAccount(e)}>ຄົ້ນຫາ</button>
                                </div>

                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12">

                                <label className="form-label"><b>ຊື່ລູກຄ້າ</b><b style={{ color: "red" }}> *</b></label>
                                <input type="text" className="form-control" value={customer_name}
                                    onChange={(e) => setCustomerName(e.target.value)}
                                    id="exampleFormControlInput1" placeholder="ຊື່ລູກຄ້າ" />

                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12">

                                <label className="form-label"><b>ເລກປະຈຳຕົວຜູ້ເສຍອາກອນ</b><b style={{ color: "red" }}> *</b></label>
                                <div className="input-group ">
                                    <input type="text" className="form-control "
                                        value={customer_tax_id}
                                        onChange={(e) => setCustomer_tax_id(e.target.value)}
                                        placeholder="XXXXXXXXXXXX"
                                        maxLength={12}
                                        aria-label="Username"
                                        aria-describedby="basic-addon1" />


                                </div>
                            </div>
                            <div className="col-lg-12 col-md-12 col-sm-12">

                                <label className="form-label"><b>ໝາຍເຫດ</b></label>
                                <div className="input-group ">
                                    <input type="text" className="form-control " rows={5}
                                        value={Remark}
                                        onChange={(e) => setRemark(e.target.value)}
                                        placeholder="ໝາຍເຫດ"
                                        aria-label="Username"
                                        maxLength={70}
                                        aria-describedby="basic-addon1" />

                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12">

                                <label className="form-label"><b>ສະກຸນເງິນ</b></label>
                                <div className="input-group ">
                                    <select className="form-select" aria-label="Default select example"
                                        value={Currency}
                                        onChange={(e) => funcSelectCurrency(e)}>
                                        <option value="0" >...ເລືອກ...</option>
                                        <option value="LAK" >...LAK...</option>
                                        <option value="USD" >...USD...</option>
                                        <option value="THB" >...THB...</option>
                                    </select>

                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12">

                                <label className="form-label"><b>ອັດຕາແລກປ່ຽນ</b></label>
                                <div className="input-group ">
                                    <input type="number" className="form-control "
                                        value={Rate}
                                        onChange={(e) => funcExchange(e)}
                                        onBlur={(e) => funcExchangeKeyUp(e)}
                                        placeholder="ອັດຕາແລກປ່ຽນ"
                                        style={{ textAlign: "center" }}
                                        aria-label="Username" aria-describedby="basic-addon1" />

                                </div>
                            </div>


                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-12">

                                <label className="form-label"><b>ເພີ່ມບັນຊີລຸກຄ້າ</b></label>
                                <div className="d-grid gap-2">
                                    <button className="btn btn-primary" onClick={(e) => OpenCustomerAccount(e)}
                                        id="basic-addon1">ເພີ່ມບັນຊີລຸກຄ້າໃໝ່</button >

                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12">

                                <label className="form-label"><b>ເພີ່ມບັນຊີລາຍຮັບ</b></label>
                                <div className="d-grid gap-2">
                                    <button className="btn btn-primary" onClick={(e) => OpenAssetAccount(e)}
                                        id="basic-addon1"><b>ເພີ່ມບັນຊີລາຍຮັບໃໝ່</b></button >

                                </div>
                            </div>
                            <div className="col-lg-12 col-md-12 col-sm-12">

                                <label className="form-label"><b>ຊື່ບັນຊີລູກຄ້າ</b></label>
                                <input type="text" className="form-control"
                                    value={ac_name_lao}
                                    disabled
                                    onChange={(e) => setCustomerName(e.target.value)}
                                    id="exampleFormControlInput1" placeholder="ຊື່ບັນຊີລູກຄ້າ" />

                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12">

                                <label className="form-label"><b>ບັນຊີທະນາຄານ</b></label>
                                <input type="text" className="form-control"
                                    value={bank_account}
                                    onChange={(e) => setBank_account(e.target.value)}
                                    id="exampleFormControlInput1" placeholder="ບັນຊີທະນາຄານ" />

                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12">

                                <label className="form-label"><b>ຊື່ທະນາຄານ</b></label>
                                <input type="text" className="form-control"
                                    value={bank_name}
                                    onChange={(e) => setBank_name(e.target.value)}
                                    id="exampleFormControlInput1" placeholder="ຊື່ທະນາຄານ" />

                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12">

                                <label className="form-label"><b>ແຂວງ</b><b style={{ color: "red" }}> *</b></label>
                                <div className="input-group ">
                                    <select className="form-select" aria-label="Default select example"
                                        value={province}
                                        onChange={(e) => FunctionSelectDistrict(e, e.target.value)}>
                                        <option value="0" >...ເລືອກ...</option>
                                        {
                                            props.province ? props.province.data.map((item, index) => {
                                                return (<option value={item.pr_name} key={index + 1}>{item.pr_name}</option>)
                                            }) : <></>
                                        }
                                    </select>

                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12">

                                <label className="form-label"><b>ເມືອງ</b><b style={{ color: "red" }}> *</b></label>
                                <select className="form-select"
                                    value={district}
                                    onChange={(e) => setDistrict(e.target.value)} aria-label="Default select example">
                                    <option value="0" >...ເລືອກ...</option>
                                    {
                                        props.district ? props.district.data.map((item, index) => {
                                            return (<option value={item.dr_name} key={index + 1}>{item.dr_name}</option>)
                                        }) : <></>
                                    }
                                </select>

                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12">

                                <label className="form-label"><b>ບ້ານ</b><b style={{ color: "red" }}> *</b></label>

                                <input type="text" className="form-control" value={village} onChange={(e) => setVillage(e.target.value)}
                                    id="exampleFormControlInput1" placeholder="ບ້ານ" />


                            </div>

                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-12">
                                {
                                    console.log(props.cate)
                                }
                                <label className="form-label"><b>ປະເພດໃບບິນ</b><b style={{ color: "red" }}> *</b></label>
                                <div className="input-group ">
                                    <select className="form-select" value={TaxCate} onChange={(e) => selectCateTaxIn(e)} aria-label="Default select example">
                                        <option value="0" >...ເລືອກ...</option>
                                        {
                                            props.cate ? props.cate.data.map((item, index) => {
                                                return (<option value={item.tsd_id} key={index + 1}>{item.tsd_name}</option>)
                                            }) : <></>
                                        }

                                    </select>

                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12">
                                <label className="form-label"><b>ປະເພດ ອມພ</b><b style={{ color: "red" }}> *</b></label>
                                <div className="input-group ">
                                    <select className="form-select" id="tax_value"
                                        onChange={(e) => ChangeTaxInvoice(e)}
                                        value={CheckVat}
                                        aria-label="Default select example">
                                        <option value="0" id="1">...ເລືອກ...</option>
                                        {
                                            props.cate_tax ? props.cate_tax.data.map((item, index) => {
                                                return (<option value={item.id} id={item.tax_value} key={index + 1}>{item.cate_tax_name}</option>)
                                            }) : <></>
                                        }
                                    </select>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-12">

                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12">

                                <label className="form-label"><b>ບັນທຶກ ແລະ ອອກບິນ</b></label>
                                <div className="d-grid gap-2">
                                    <button type="button"
                                        onClick={(e) => SaveTaxInvoice(e)}
                                        className="btn btn-success">ບັນທຶກ ແລະ ອອກບິນ</button>

                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </Card>
            <br />

            <div className="table-responsive">
                <table className="table table-hover table-striped table-bordered">
                    <thead style={{ backgroundColor: 'white' }}>
                        <tr>
                            <th scope="col" style={{ width: "50px" }}>ລຳດັບ</th>
                            <th scope="col" style={{ width: "150px" }}>ເລກບັນຊີ</th>
                            <th scope="col">ເນື້ອໃນ</th>
                            <th scope="col" style={{ width: "100px" }}>ຫົວໜ່ວຍ</th>
                            <th scope="col" style={{ width: "100px" }}>ຈຳນວນ</th>
                            <th scope="col" style={{ width: "170px" }}>ລາຄາ</th>
                            <th scope="col" style={{ width: "170px" }}>ລາຄາຫລັງການຄິດໄລ່</th>
                            <th scope="col" style={{ width: "200px" }}>ລວມມຸນຄ່າ</th>
                            <th scope="col" style={{ width: "80px" }}>ລຶບອອກ</th>
                        </tr>
                    </thead>
                    <tbody>

                        {
                            TaxInvoice.map((item, index) => {
                                return (
                                    <tr key={index} style={{ padding: "50px", backgroundColor: item.acc_check ? "#cff0ca" : "#f0caca" }}>
                                        <th scope="row" style={{ textAlign: 'center', padding: "0px" }}>{index + 1}</th>
                                        <td style={{ padding: "0px" }}>
                                            <div className="input-group ">
                                                <input style={{ textAlign: 'center' }}
                                                    type="text"
                                                    onChange={(e) => FuncSelectAccountingCode(e, index)}
                                                    onKeyDown={(e) => FuncSelectAccountingCodeDetails(e, index)}
                                                    className="form-control"
                                                    placeholder="ເລກບັນຊີລາຍຮັບ"
                                                    value={item.acc_code}
                                                    aria-label="Username"
                                                    aria-describedby="basic-addon1" />
                                                <button onClick={(e) => OpenAssetAccount(e, index)} className="btn btn-primary">ຄົ້ນຫາ</button>
                                            </div>
                                        </td>
                                        <td style={{ padding: "0px" }}>
                                            <input type="text"
                                                className="form-control"
                                                placeholder="ເນື້ອໃນລາຍການ"
                                                value={item.acc_name}
                                                onChange={(e) => UpdateAccountingName(e, index)}
                                                aria-label="Username"
                                                aria-describedby="basic-addon1" />
                                        </td>
                                        <td style={{ padding: "0px" }}>
                                            <input style={{ textAlign: 'center' }}
                                                type="text"
                                                className="form-control"
                                                placeholder="ຫົວໜ່ວຍ"
                                                value={item.acc_unit}
                                                onChange={(e) => UpdateAccountingUnitName(e, index)}
                                                aria-label="Username"
                                                aria-describedby="basic-addon1" />
                                        </td>
                                        <td style={{ padding: "0px" }}>
                                            <input style={{ textAlign: 'center' }}
                                                type="number"
                                                className="form-control"
                                                onChange={(e) => UpdateProductQTy(e, index)}
                                                value={item.acc_qty}
                                                placeholder="ຈຳນວນ"
                                                aria-label="Username"
                                                aria-describedby="basic-addon1" />


                                        </td>
                                        <td style={{ padding: "0px" }}>
                                            <div className="input-group ">
                                                <span className="input-group-text " style={{ padding: "2px" }} id="basic-addon1">{Currency}</span>
                                                <NumericFormat
                                                    className="form-control"
                                                    style={{ textAlign: 'right' }}
                                                    onChange={(e) => UpdateProductPrice(e, index)}
                                                    value={item.acc_price}
                                                    allowLeadingZeros
                                                    thousandSeparator="."
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    decimalSeparator=","
                                                    allowNegative={false}
                                                />
                                            </div>
                                            <div className="input-group ">
                                                <span className="input-group-text " style={{ padding: "2px" }} id="basic-addon1">LAK</span>
                                                <div style={{ textAlign: 'right', backgroundColor: "#cff0ca" }}
                                                    className="form-control" >
                                                    {
                                                        accountingLaoFormat(Number(accountingEngFormat(item.acc_price) * Rate))
                                                    }
                                                </div>
                                            </div>


                                        </td>
                                        <td style={{ padding: "0px" }}>
                                            <div className="input-group ">
                                                <span className="input-group-text " style={{ padding: "2px" }} id="basic-addon1">{Currency}</span>
                                                <div style={{ textAlign: 'right', backgroundColor: "#e9ecef" }}
                                                    className="form-control" >
                                                    {
                                                        accountingLaoFormat(Number(TaxInOut === "1" ?
                                                            (Number(accountingEngFormat(item.acc_price))) : TaxInOut === "2" ?
                                                                Number((accountingEngFormat(item.acc_price) * 100) / TaxCateInvoiceOut) : 0))
                                                    }
                                                </div>
                                            </div>
                                            <div className="input-group ">
                                                <span className="input-group-text " style={{ padding: "2px" }} id="basic-addon1">LAK</span>
                                                <div style={{ textAlign: 'right', backgroundColor: "#cff0ca" }}
                                                    className="form-control" >
                                                    {
                                                        accountingLaoFormat(Number(Number(TaxInOut === "1" ?
                                                            (Number(accountingEngFormat(item.acc_price))) : TaxInOut === "2" ?
                                                                Number((accountingEngFormat(item.acc_price) * 100) / TaxCateInvoiceOut) : 0) * Rate))
                                                    }
                                                </div>
                                            </div>
                                        </td>
                                        <td style={{ padding: "0px" }}>
                                            <div className="input-group ">
                                                <span className="input-group-text " style={{ padding: "2px" }} id="basic-addon1">{Currency}</span>
                                                <div style={{ textAlign: 'right', backgroundColor: "#e9ecef" }}
                                                    className="form-control" >
                                                    {
                                                        accountingLaoFormat(Number(TaxInOut === "1" ?
                                                            (Number(accountingEngFormat(item.acc_price) * (item.acc_qty))) : TaxInOut === "2" ?
                                                                Number(Number((accountingEngFormat(item.acc_price) * item.acc_qty) * 100) / TaxCateInvoiceOut) : 0))
                                                    }
                                                </div>
                                            </div>
                                            <div className="input-group ">
                                                <span className="input-group-text " style={{ padding: "2px" }} id="basic-addon1">LAK</span>
                                                <div style={{ textAlign: 'right', backgroundColor: "#cff0ca" }}
                                                    className="form-control" >
                                                    {
                                                        accountingLaoFormat(Number(Number(TaxInOut === "1" ?
                                                            (Number(accountingEngFormat(item.acc_price) * (item.acc_qty))) : TaxInOut === "2" ?
                                                                Number(Number((accountingEngFormat(item.acc_price) * item.acc_qty) * 100) / TaxCateInvoiceOut) : 0)) * Rate)
                                                    }
                                                </div>
                                            </div>


                                        </td>
                                        <td style={{ padding: "0px", textAlign: "center", backgroundColor: "white" }}>
                                            <button onClick={(e) => funcRemoveTaxInvoice(e, index)} className="btn btn-danger">
                                                ລຶບ
                                            </button>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>

            </div>

            <div className="row">
                <div className="col-lg-4 col-md-2 ">
                    <button className="btn btn-success" onClick={(e) => funcAutoAddNewItem(e)}>ເພີ່ມລາຍການ</button>
                </div>
                <div className="col-lg-4 col-md-5 col-sm-6">
                    <div className="input-group ">
                        <span className="input-group-text"
                            id="basic-addon1"><b>ລວມມຸນຄ່າ (ອ.ມ.ພ) {Currency}</b></span>

                        <div className="form-control" style={{ textAlign: "right" }}
                            id="basic-addon1"><b style={{ textAlign: "right" }}>{
                                accountingLaoFormat(
                                    Number(Number(TaxInOut === "1" ?
                                        Number(
                                            TaxInvoice.reduce(function (_this, val) {
                                                return _this + (accountingEngFormat(val.acc_price) * val.acc_qty);
                                            }, 0) * ((TaxCateInvoiceOut - 100) / 100)
                                        )
                                        : TaxInOut === "2" ?
                                            Number((
                                                TaxInvoice.reduce(function (_this, val) {
                                                    return _this + (accountingEngFormat(val.acc_price) * val.acc_qty);
                                                }, 0) * 100) / TaxCateInvoiceOut) * ((TaxCateInvoiceOut - 100) / 100) : 0)))
                            }</b></div>


                    </div>
                    <br />
                    <div className="input-group ">
                        <span className="input-group-text"
                            id="basic-addon1"><b>ລວມມຸນຄ່າ (ອ.ມ.ພ) LAK</b></span>

                        <div className="form-control" style={{ textAlign: "right" }}
                            id="basic-addon1"><b style={{ textAlign: "right" }}>{
                                accountingLaoFormat(
                                    Number(Number(TaxInOut === "1" ?
                                        Number(
                                            TaxInvoice.reduce(function (_this, val) {
                                                return _this + (accountingEngFormat(val.acc_price) * val.acc_qty);
                                            }, 0) * ((TaxCateInvoiceOut - 100) / 100)
                                        )
                                        : TaxInOut === "2" ?
                                            Number((
                                                TaxInvoice.reduce(function (_this, val) {
                                                    return _this + (accountingEngFormat(val.acc_price) * val.acc_qty);
                                                }, 0) * 100) / TaxCateInvoiceOut) * ((TaxCateInvoiceOut - 100) / 100) : 0)) * Rate)
                            }</b></div>


                    </div>
                </div>
                <div className="col-lg-4 col-md-5 col-sm-6">
                    <div className="input-group ">
                        <span className="input-group-text"
                            id="basic-addon1"><b>ລວມມຸນຄ່າທັງໝົດ {Currency}</b></span>
                        <div className="form-control" style={{ textAlign: "right" }}
                            id="basic-addon1"><b style={{ textAlign: "right" }}>{
                                accountingLaoFormat(
                                    (
                                        Number(
                                            Number(TaxInOut === "1" ?
                                                Number(
                                                    TaxInvoice.reduce(function (_this, val) {
                                                        return _this + (accountingEngFormat(val.acc_price) * val.acc_qty);
                                                    }, 0) * ((TaxCateInvoiceOut - 100) / 100) + TaxInvoice.reduce(function (_this, val) {
                                                        return _this + (accountingEngFormat(val.acc_price) * val.acc_qty);
                                                    }, 0)
                                                )
                                                : TaxInOut === "2" ?
                                                    (Number((
                                                        TaxInvoice.reduce(function (_this, val) {
                                                            return _this + (accountingEngFormat(val.acc_price) * val.acc_qty);
                                                        }, 0) * 100) / TaxCateInvoiceOut) * ((TaxCateInvoiceOut - 100) / 100)) + ((
                                                            TaxInvoice.reduce(function (_this, val) {
                                                                return _this + (accountingEngFormat(val.acc_price) * val.acc_qty);
                                                            }, 0) * 100) / TaxCateInvoiceOut) : 0))
                                    )

                                )
                            }</b></div>
                    </div>
                    <br />
                    <div className="input-group ">
                        <span className="input-group-text"
                            id="basic-addon1"><b>ລວມມຸນຄ່າທັງໝົດ LAK</b></span>
                        <div className="form-control" style={{ textAlign: "right" }}
                            id="basic-addon1"><b style={{ textAlign: "right" }}>{
                                accountingLaoFormat(
                                    (
                                        Number(
                                            Number(TaxInOut === "1" ?
                                                Number(
                                                    TaxInvoice.reduce(function (_this, val) {
                                                        return _this + (accountingEngFormat(val.acc_price) * val.acc_qty);
                                                    }, 0) * ((TaxCateInvoiceOut - 100) / 100) + TaxInvoice.reduce(function (_this, val) {
                                                        return _this + (accountingEngFormat(val.acc_price) * val.acc_qty);
                                                    }, 0)
                                                )
                                                : TaxInOut === "2" ?
                                                    (Number((
                                                        TaxInvoice.reduce(function (_this, val) {
                                                            return _this + (accountingEngFormat(val.acc_price) * val.acc_qty);
                                                        }, 0) * 100) / TaxCateInvoiceOut) * ((TaxCateInvoiceOut - 100) / 100)) + ((
                                                            TaxInvoice.reduce(function (_this, val) {
                                                                return _this + (accountingEngFormat(val.acc_price) * val.acc_qty);
                                                            }, 0) * 100) / TaxCateInvoiceOut) : 0))
                                    ) * Rate

                                )
                            }</b></div>
                    </div>
                </div>
            </div>




            {/* insert customer account */}
            <div>
                <Dialog
                    fullScreen
                    open={customerOpen}
                    onClose={handleClose}
                    TransitionComponent={Transition}
                >
                    <AppBar sx={{ position: 'relative' }} style={{
                        backgroundColor: '#0564c8',
                        color: "white"
                    }}>
                        <Toolbar>
                            <IconButton
                                edge="start"
                                style={{
                                    backgroundColor: 'red',
                                    color: 'white'
                                }}
                                onClick={handleClose}
                                aria-label="close"
                            >
                                <CloseIcon />
                            </IconButton>
                            <Typography sx={{ ml: 2, flex: 1 }} style={{ textAlign: 'center' }} variant="h6" component="div">
                                <b>ເພີ່ມບັນຊີລຸກຄ້າໃໝ່</b>
                            </Typography>

                        </Toolbar>
                    </AppBar>
                    <DialogContent style={{
                        backgroundColor: "whitesmoke"
                    }}>
                        <div ref={customer_acc}>
                            <div className="row" >
                                {/* <div className="col-lg-3">
                                    <div className="">
                                        <label className="form-label">ສາຂາ</label>
                                        <div className="input-group ">
                                            <input type="text" className="form-control" readOnly style={{ textAlign: "center" }}
                                                value={localStorage.getItem('off_id')}
                                                id="exampleFormControlInput1" placeholder="ຊື່ບັນຊີລູກຄ້າ" />
                                            <input type="text" className="form-control" disabled style={{ textAlign: "center" }} value={localStorage.getItem('off_nm')}
                                                id="exampleFormControlInput1" placeholder="ຊື່ບັນຊີລູກຄ້າ" />
                                        </div>
                                    </div>
                                </div> */}
                                <div className="col-lg-3">

                                    <label className="form-label"><b>ເລກບັນຊີ</b></label>
                                    <input type="number"
                                        disabled={acc_code_status}
                                        onKeyDown={(e) => funcSelectAccountGroup(e)}
                                        value={ac_code}
                                        onChange={(e) => setAc_code(e.target.value)}

                                        className="form-control"
                                        id="exampleFormControlInput1" placeholder="ເລກບັນຊີ" />

                                </div>
                                <div className="col-lg-3">

                                    <label className="form-label"><b>ຊື່ບັນຊີ ພາສາລາວ</b></label>
                                    <input type="text" className="form-control"
                                        value={ac_name_lao}
                                        onChange={(e) => setAc_name_lao(e.target.value)}
                                        id="exampleFormControlInput1" placeholder="ຊື່ບັນຊີ ພາສາລາວ" />

                                </div>
                                <div className="col-lg-3">

                                    <label className="form-label"><b>ຊື່ບັນຊີ ພາສາອັງກິດ</b></label>
                                    <input type="text" className="form-control"
                                        value={ac_name_eng}
                                        onChange={(e) => setAc_name_eng(e.target.value)}
                                        id="exampleFormControlInput1" placeholder="ຊື່ບັນຊີ ພາສາອັງກິດ" />

                                </div>
                                <div className="col-lg-3">

                                    <label className="form-label"><b>ຊື່ບັນຊີ ພາສາຈິນ</b></label>
                                    <input type="text" className="form-control"
                                        value={ac_name_ch}
                                        onChange={(e) => setAc_name_ch(e.target.value)}
                                        id="exampleFormControlInput1" placeholder="ຊື່ບັນຊີ ພາສາຈິນ" />

                                </div>
                                <div className="col-lg-3">

                                    <label className="form-label"><b>ຊື່ບັນຊີລູກຄ້າ</b></label>
                                    <input type="text"
                                        className="form-control"
                                        value={customer_name}
                                        onChange={(e) => setCustomerName(e.target.value)}
                                        id="exampleFormControlInput1"
                                        placeholder="ຊື່ບັນຊີລູກຄ້າ" />

                                </div>
                                <div className="col-lg-3">

                                    <label className="form-label"><b>ເລກຜູ້ເສຍອາກອນ</b></label>
                                    <div className="input-group ">
                                        <input type="text" className="form-control"
                                            value={customer_tax_id}
                                            placeholder="XXXXXXXXXXXX"
                                            maxLength={12}
                                            onChange={(e) => setCustomer_tax_id(e.target.value)}
                                            aria-label="Username"
                                            aria-describedby="basic-addon1" />


                                    </div>
                                </div>
                                <div className="col-lg-3">

                                    <label className="form-label"><b>ເບີໂທລະສັບ</b></label>
                                    <input type="text" className="form-control"
                                        value={customer_tel}
                                        onChange={(e) => setCustomer_tel(e.target.value)}
                                        id="exampleFormControlInput1" placeholder="ເບີໂທລະສັບ" />

                                </div>
                                <div className="col-lg-3">

                                    <label className="form-label"><b>ເລກບັນຊີທະນາຄານ</b></label>
                                    <input type="text" className="form-control"
                                        value={bank_account}
                                        onChange={(e) => setBank_account(e.target.value)}
                                        id="exampleFormControlInput1" placeholder="ເລກບັນຊີທະນາຄານ" />

                                </div>
                                <div className="col-lg-3">

                                    <label className="form-label"><b>ຊື່ບັນຊີທະນາຄານ</b></label>
                                    <input type="text" className="form-control"
                                        value={bank_name}
                                        onChange={(e) => setBank_name(e.target.value)}
                                        id="exampleFormControlInput1" placeholder="ຊື່ບັນຊີທະນາຄານ" />

                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-12">

                                    <label className="form-label"><b>ແຂວງ</b></label>
                                    <div className="input-group ">
                                        <select className="form-select"
                                            value={province}
                                            onChange={(e) => FunctionSelectDistrict(e, e.target.value)} aria-label="Default select example">
                                            <option value="0" >...ເລືອກ...</option>
                                            {
                                                props.province ? props.province.data.map((item, index) => {
                                                    return (<option value={item.pr_name} key={index + 1}>{item.pr_name}</option>)
                                                }) : <></>
                                            }
                                        </select>

                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-12">

                                    <label className="form-label"><b>ເມືອງ</b></label>
                                    <select className="form-select"
                                        value={district}
                                        onChange={(e) => setDistrict(e.target.value)} aria-label="Default select example">
                                        <option value="0" >...ເລືອກ...</option>
                                        {
                                            props.district ? props.district.data.map((item, index) => {
                                                return (<option value={item.dr_name} key={index + 1}>{item.dr_name}</option>)
                                            }) : <></>
                                        }
                                    </select>

                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-12">

                                    <label className="form-label"><b>ບ້ານ</b></label>


                                    <input type="text" className="form-control" value={village} onChange={(e) => setVillage(e.target.value)}
                                        id="exampleFormControlInput1" placeholder="ບ້ານ" />


                                </div>
                            </div>
                            <br />
                            <div className="row">
                                <div className="col-lg-6 col-md-6">
                                    <div className="d-grid gap-2 d-md-block">
                                        <input type="text"
                                            className="form-control"
                                            onKeyDown={(e) => functionSelectCustomerAccount(e)}

                                            id="exampleFormControlInput1"
                                            placeholder="ຄົ້ນຫາບັນຊີລູກຄ້າ ກົດ Enter ເພື່ອຄົ້ນຫາ" />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6">
                                    <div className="d-grid gap-2 d-md-flex justify-content-md-end">

                                        <button className="btn btn-danger" onClick={(e) => funcCusClear(e)} type="button">ລ້າງຂໍ້ມູນ</button>
                                        {
                                            UpdateStatus === true ? <button className="btn btn-primary" onClick={(e) => funcUpdateAccountGroup(e)} type="button">ແກ້ໄຂຂໍ້ມູນ</button>
                                                : <button className="btn btn-success" onClick={(e) => funcCreateAccountGroup(e)} type="button">ບັນທຶກຂໍ້ມູນ</button>
                                        }

                                    </div>
                                </div>
                            </div>

                            <hr />

                            <div className="table-responsive">
                                <table className="table table-hover table-striped table-bordered " style={{ backgroundColor: 'white' }}>
                                    <thead style={{ backgroundColor: 'white' }}>
                                        <tr>
                                            <th scope="col" style={{ width: "50px" }}>ລຳດັບ</th>
                                            <th scope="col" style={{ width: "50px" }}>ແກ້ໄຂ</th>
                                            <th scope="col" style={{ width: "100px" }}>ເລກຜັງບັນຊີ</th>
                                            <th scope="col" style={{ width: "200px" }}>ເນື້ອໃນຜັງບັນຊີພາສາລາວ</th>
                                            <th scope="col" style={{ width: "200px" }}>ຊື່ບັນຊີລູກຄ້າ</th>
                                            <th scope="col" style={{ width: "200px" }}>ເນື້ອໃນຜັງບັນຊີພາອັງກິດ</th>
                                            <th scope="col" style={{ width: "200px" }}>ເນື້ອໃນຜັງບັນຊີພາສາຈິນ</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            props.customer_acc ?
                                                props.customer_acc.data.length > 0 ?
                                                    props.customer_acc.data.map((item, index) => {
                                                        return (
                                                            <tr key={index + 1}>
                                                                <th scope="row" style={{ textAlign: 'center' }}>{index + 1}</th>
                                                                <td>

                                                                    {

                                                                        limitUpdateValueCus.includes(Number(item.AC_CODE)) ? <></>
                                                                            : <button className="btn btn-primary" onClick={(e) => scrollTopCus(item)} type="button">ແກ້ໄຂ</button>
                                                                    }
                                                                </td>
                                                                <td>
                                                                    <b style={{ color: "blue", cursor: "pointer" }} onClick={(e) => funcSelectAccountGroupCustomerFind(item.AC_CODE)}> {item.AC_CODE}</b>
                                                                </td>
                                                                <td>
                                                                    {item.Name_L}
                                                                </td>
                                                                <td>
                                                                    {item.AcName_c}
                                                                </td>
                                                                <td>
                                                                    {item.Name_E}
                                                                </td>
                                                                <td>
                                                                    {item.Name_Ch}
                                                                </td>


                                                            </tr>
                                                        )
                                                    }) : <></>
                                                : <></>
                                        }

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </DialogContent>
                </Dialog>
            </div>



            {/* insert asset account */}
            <div>
                <Dialog
                    fullScreen
                    open={assetOpen}
                    onClose={handleClose}
                    TransitionComponent={Transition}
                    style={{
                        backgroundColor: "whitesmoke"
                    }}
                >
                    <AppBar sx={{ position: 'relative' }} style={{
                        backgroundColor: '#0564c8',
                        color: "white"
                    }}>
                        <Toolbar>
                            <IconButton
                                edge="start"

                                style={{
                                    backgroundColor: 'red',
                                    color: 'white'
                                }}
                                onClick={handleClose}
                                aria-label="close"
                            >
                                <CloseIcon />
                            </IconButton>
                            <Typography sx={{ ml: 2, flex: 1 }} style={{ textAlign: 'center' }} variant="h6" component="div">
                                <b>ເພີ່ມບັນຊີລາຍຮັບໃໝ່</b>
                            </Typography>

                        </Toolbar>
                    </AppBar>
                    <DialogContent style={{
                        backgroundColor: "whitesmoke"
                    }}>

                        <div ref={asset_acc}>
                            <div className="row" >
                                <div className="col-lg-3">

                                    <label className="form-label"><b>ສາຂາ</b></label>
                                    <div className="input-group ">
                                        <input type="text" className="form-control" readOnly style={{ textAlign: "center" }}
                                            value={localStorage.getItem('off_id')}
                                            id="exampleFormControlInput1" placeholder="ຊື່ບັນຊີລູກຄ້າ" />
                                        <input type="text" className="form-control" disabled style={{ textAlign: "center" }} value={localStorage.getItem('off_nm')}
                                            id="exampleFormControlInput1" placeholder="ຊື່ບັນຊີລູກຄ້າ" />

                                    </div>
                                </div>
                                <div className="col-lg-3">

                                    <label className="form-label"><b>ເລກບັນຊີ</b></label>
                                    <input type="number"
                                        disabled={acc_code_status}
                                        onKeyDown={(e) => funcSelectAccountAsGroup(e)}
                                        value={AcAS_code}
                                        onChange={(e) => setAcAS_code(e.target.value)}
                                        className="form-control"
                                        id="exampleFormControlInput1" placeholder="ເລກບັນຊີ" />

                                </div>
                                <div className="col-lg-6">
                                    <label className="form-label"><b>ຊື່ບັນຊີ ພາສາລາວ</b></label>
                                    <input type="text"
                                        className="form-control"
                                        value={AcAS_name_lao}
                                        onChange={(e) => setAcAS_name_lao(e.target.value)}
                                        id="exampleFormControlInput1"
                                        placeholder="ຊື່ບັນຊີ ພາສາລາວ" />

                                </div>
                                <div className="col-lg-4">
                                    <label className="form-label"><b>ຊື່ບັນຊີ ພາສາອັງກິດ</b></label>
                                    <input type="text" className="form-control"
                                        value={AcAS_name_eng}
                                        onChange={(e) => setAcAS_name_eng(e.target.value)}
                                        id="exampleFormControlInput1"
                                        placeholder="ຊື່ບັນຊີ ພາສາອັງກິດ" />

                                </div>
                                <div className="col-lg-4">
                                    <label className="form-label"><b>ຊື່ບັນຊີ ພາສາຈິນ</b></label>
                                    <input type="text"
                                        className="form-control"
                                        value={AcAS_name_ch}
                                        onChange={(e) => setAcAS_name_ch(e.target.value)}
                                        id="exampleFormControlInput1"
                                        placeholder="ຊື່ບັນຊີ ພາສາຈິນ" />

                                </div>
                                <div className="col-lg-4">

                                    <label className="form-label"><b>ຫົວໜ່ວຍ ສິນຄ້າ</b></label>
                                    <input type="text"
                                        className="form-control"
                                        onChange={(e) => setAc_unit(e.target.value)}
                                        value={ac_unit}
                                        id="exampleFormControlInput1"
                                        placeholder="ຫົວໜ່ວຍ ສິນຄ້າ" />
                                </div>
                            </div>
                            <br />
                            <div className="row">
                                <div className="col-lg-6 col-md-6">
                                    <div className="d-grid gap-2 d-md-block">
                                        <input type="text"
                                            className="form-control"
                                            onKeyDown={(e) => functionSelectAssetAccount(e)}

                                            id="exampleFormControlInput1"
                                            placeholder="ຄົ້ນຫາບັນຊີລາຍຮັບ ກົດ Enter ເພື່ອຄົ້ນຫາ" />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6">

                                    <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                        <button className="btn btn-danger" onClick={() => funcAssClear()} type="button">ລ້າງຂໍ້ມູນ</button>
                                        {UpdateStatus === true ? <button className="btn btn-primary" onClick={(e) => funcUpdateAccountAsGroup(e)} type="button">ແກ້ໄຂຂໍ້ມູນ</button>
                                            : <button className="btn btn-success" onClick={(e) => funcCreateAccountAsGroup(e)} type="button">ບັນທຶກຂໍ້ມູນ</button>}
                                    </div>
                                </div>
                            </div>

                            <hr />
                            <div className="table-responsive">
                                <table className="table table-hover table-striped table-bordered " style={{ backgroundColor: 'white' }}>
                                    <thead style={{ backgroundColor: 'white' }}>
                                        <tr>
                                            <th scope="col" style={{ width: "50px" }}>ລຳດັບ</th>
                                            <th scope="col" style={{ width: "50px" }}>ແກ້ໄຂ</th>
                                            <th scope="col" style={{ width: "50px" }}>ເລກຜັງບັນຊີ</th>
                                            <th scope="col" style={{ width: "200px" }}>ເນື້ອໃນຜັງບັນຊີພາສາລາວ</th>
                                            <th scope="col" style={{ width: "200px" }}>ເນື້ອໃນຜັງບັນຊີພາອັງກິດ</th>
                                            <th scope="col" style={{ width: "200px" }}>ເນື້ອໃນຜັງບັນຊີພາສາຈິນ</th>
                                            <th scope="col" style={{ width: "50px" }}>ຫົວໜ່ວຍ</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {
                                            props.asset_acc ?
                                                props.asset_acc.data.length > 0 ?
                                                    props.asset_acc.data.map((item, index) => {
                                                        return (
                                                            <tr key={index + 1}>
                                                                <th scope="row" style={{ textAlign: 'center' }}>{index + 1}</th>
                                                                <td>
                                                                    {
                                                                        limitUpdateValueAs.includes(Number(item.AC_CODE)) ? <></>
                                                                            : <button className="btn btn-primary" onClick={(e) => scrollTopAss(item)} type="button">ແກ້ໄຂ</button>
                                                                    }
                                                                </td>
                                                                <td>
                                                                    <b style={{ color: "blue", cursor: "pointer" }} onClick={(e) => funcSelectAccountGroupCustomerFindInCome(item)}> {item.AC_CODE}</b>
                                                                </td>
                                                                <td>
                                                                    {item.Name_L}
                                                                </td>
                                                                <td>
                                                                    {item.Name_E}
                                                                </td>
                                                                <td>
                                                                    {item.Name_Ch}
                                                                </td>

                                                                <td>
                                                                    {item.AcUnit_c}
                                                                </td>

                                                            </tr>
                                                        )
                                                    }) : <></>
                                                : <></>
                                        }

                                    </tbody>
                                </table>
                            </div>
                        </div>

                    </DialogContent>
                </Dialog>
            </div>
            <br></br>
            <br></br>
            <br></br>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        province: state.Company.province,
        district: state.Company.district,
        village: state.Company.village,
        customer_acc: state.Company.customer_acc,
        asset_acc: state.Company.asset_acc,
        cate: state.Company.cate,
        cate_tax: state.Company.cate_tax
    }
}


export default connect(mapStateToProps)(CreateInvoice);

