import React from 'react';
import ReactDOM from 'react-dom';
import './i18n';
import { BrowserRouter } from 'react-router-dom';
import App from './Routes/index.routes';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import promiseMiddleware from 'redux-promise';
import ReduxThunk from 'redux-thunk';
import Reducer from './API/indexReducer';
import { createTheme } from '@mui/material';
import { ThemeProvider } from '@emotion/react';


const theme = createTheme({
    palette: {
        primary: {
            light: '#5d945f',
            main: '#0564c8',
            dark: '#357a38',
            contrastText: '#fff',
        },
        secondary: {
            light: '#f50057',
            main: '#0564c8',
            dark: '#ab003c',
            contrastText: '#fff',
        },
    },

});




const createStoreWithMiddleware = applyMiddleware(promiseMiddleware, ReduxThunk)(createStore);

ReactDOM.render(
    <ThemeProvider theme={theme}>
        <Provider store={createStoreWithMiddleware(Reducer)}>
        {/* <Provider store={createStoreWithMiddleware(Reducer, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__())}> */}
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </Provider>
    </ThemeProvider>

    , document.getElementById('root'));
