
export const API_PATH = "https://api.taxinvoice.la/api"
export const URL_QR = "https://taxinvoice.la/dashboard/bill-tax-invoice-online"


export const USER_GET_AUTH = "USER_GET_AUTH";
export const USER_LOGIN = "USER_GET_AUTH";
export const USER_RESET_TOKEN = "USER_RESET_TOKEN";
export const USER_LOGIN_VERIFY = "USER_LOGIN_VERIFY";


export const PROVINCE = "PROVINCE"
export const DISTRICT = "DISTRICT"
export const VILLAGE = "VILLAGE"
export const CUSTOMER_ACCOUNT = "CUSTOMER_ACCOUNT"
export const ASSET_ACCOUNT = "ASSET_ACCOUNT"
export const CREATE_ACCOUNT = "CREATE_ACCOUNT"
export const SELECT_ACCOUNT_BY_CODE = "SELECT_ACCOUNT_BY_CODE"
export const AP_ACC_Gen = "AP_ACC_Gen"
export const CATE = "CATE"
export const CATE_TAX = "CATE_TAX"
export const TAX_INVOICE="TAX_INVOICE"



export const exceptThisSymbols = ["e", "E", "+", "-"];



export const accountingLaoFormat = (inputNumber) => {

    let number = (Number(inputNumber)).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    var NumberCustom = "";
    number.split('').map(item => {
        if (item === ",") {
            NumberCustom += "."
        } else if (item === ".") {
            NumberCustom += ","
        } else {
            NumberCustom += item;
        }
    })

    return NumberCustom.toString();
}
