import { Card } from '@mui/material'
import React from 'react'
import { Container } from 'reactstrap'
import AddTaskIcon from '@mui/icons-material/AddTask';
import { Link } from 'react-router-dom';
export default function Thanks() {
    return (
        <div>
            <div className="center_center">

                <Card style={{ maxWidth: 700, }}>

                    <div className="login_header">
                        <br></br>

                        {/* <Avatar className="login_logo" alt="LOGO" src="./logo.png" /> */}


                        <h2><b>ຂັນຕອນການຂໍ້ລະຫັດເພື່ອກູ້ບັນຊີ</b></h2>
                        <h5><b>ລະບົບໄດ້ສົ່ງອີເມວເຂົ້າໄປຍັງບັນຊີຂອງທ່ານແລ້ວ</b></h5>
                    </div>
                    <hr />

                    <Container>




                        <div >
                            <center>
                                <AddTaskIcon style={{ fontSize: 300, color: 'green' }} />
                            </center>


                        </div >


                        <br></br>

                        <center>
                            <Link to={'/login'}> <h4><b>ເຂົ້າສູ່ລະບົບ</b></h4></Link>

                        </center>

                        <br></br>
                    </Container>
                </Card>
            </div>
        </div >

    )
}
