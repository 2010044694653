
import React from 'react'
import { Alert, Snackbar, Stack } from '@mui/material';

const AlertFunction = ({ onClose, data }) => { 
    return (
        <Stack spacing={2} sx={{ width: '100%' }}>
            <Snackbar anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
                open={data.open} autoHideDuration={6000} onClose={onClose}>
                <Alert onClose={onClose} variant="filled" severity={data.color} sx={{ width: '100%' }}>
                    {data.message}
                </Alert>
            </Snackbar>
        </Stack>
    )
}


export default AlertFunction