import axios from 'axios';
import { API_PATH, AP_ACC_Gen, ASSET_ACCOUNT, CATE, CATE_TAX, CREATE_ACCOUNT, CUSTOMER_ACCOUNT, DISTRICT, PROVINCE,  SELECT_ACCOUNT_BY_CODE,  TAXINVOICE,  TAX_INVOICE,  VILLAGE } from '../Type/Type';



export async function SelectCustomerAccounting(data) {
    const request = await axios.post(API_PATH+"/office/customer/account/group",data ,  {
        headers: {
            "Content-Type":"application/json; charset=utf-8",
            "Authorization": localStorage.getItem('TaxToken')
        },
    }).then((res) => res.data);

    return {
        type: CUSTOMER_ACCOUNT,
        payload: request
    }
}   

export async function SelectCustomerAccountingSearchName(data) {
    const request = await axios.post(API_PATH+"/office/customer/account/group/name",data ,  {
        headers: {
            "Content-Type":"application/json; charset=utf-8",
            "Authorization": localStorage.getItem('TaxToken')
        },
    }).then((res) => res.data);

    return {
        type: CUSTOMER_ACCOUNT,
        payload: request
    }
}   

export async function SelectAssetAccounting(data) {
    const request = await axios.post(API_PATH+"/office/asset/account/group",data ,  {
        headers: {
            "Authorization": localStorage.getItem('TaxToken')
        },
    }).then((res) => res.data);

    return {
        type: ASSET_ACCOUNT,
        payload: request
    }
}   

export async function SelectAssetAccountingSearchName(data) {
    const request = await axios.post(API_PATH+"/office/asset/account/group/name",data ,  {
        headers: {
            "Authorization": localStorage.getItem('TaxToken')
        },
    }).then((res) => res.data);

    return {
        type: ASSET_ACCOUNT,
        payload: request
    }
}  

export async function CreateAccountGroup(data) {
    const request = await axios.post(API_PATH+"/office/account/group/create",data ,  {
        headers: {
            "Authorization": localStorage.getItem('TaxToken')
        },
    }).then((res) => res.data);

    return {
        type: CREATE_ACCOUNT,
        payload: request
    }
}   

export async function updateAccountGroup(data) {
    const request = await axios.post(API_PATH+"/office/account/group/update",data ,  {
        headers: {
            "Authorization": localStorage.getItem('TaxToken')
        },
    }).then((res) => res.data);

    return {
        type: "UPDATE_ACCOUNT",
        payload: request
    }
} 


export async function SelectAccountCustomerGroupById(data) {
    const request = await axios.post(API_PATH+"/office/account/group/list/customer",data ,  {
        headers: {
            "Authorization": localStorage.getItem('TaxToken')
        },
    }).then((res) => res.data);

    return {
        type: SELECT_ACCOUNT_BY_CODE,
        payload: request
    }
}  

export async function SelectAccountAssetGroupById(data) {
    const request = await axios.post(API_PATH+"/office/account/group/list/asset",data ,  {
        headers: {
            "Authorization": localStorage.getItem('TaxToken')
        },
    }).then((res) => res.data);

    return {
        type: SELECT_ACCOUNT_BY_CODE,
        payload: request
    }
} 



export async function selectAP_ACC_Gen(data) {
    const request = await axios.post(API_PATH+"/office/tax-invoice/list",data ,  {
        headers: {
            "Authorization": localStorage.getItem('TaxToken')
        },
    }).then((res) => res.data);

    return {
        type: AP_ACC_Gen,
        payload: request
    }
} 




export async function SelectTaxInvoice(data) {
    const request = await axios.post(API_PATH+"/office/invoice/list",data ,  {
        headers: {
            "Authorization": localStorage.getItem('TaxToken')
        },
    }).then((res) => res.data);

    return {
        type: TAX_INVOICE,
        payload: request
    }
} 



















export async function SelectProvince() {
    const request = await axios.get(API_PATH+"/province",  {
        headers: {
            "Authorization": localStorage.getItem('TaxToken')
        },
    }).then((res) => res.data);

    return {
        type: PROVINCE,
        payload: request
    }
}   

export async function SelectDistrict(data) {
    
    const request = await axios.post(API_PATH+"/district",data, {
        headers: {
            "Authorization": localStorage.getItem('TaxToken')
        },
    }).then((res) => res.data);

    return {
        type: DISTRICT,
        payload: request
    }
}  



export async function selectCateInvoice(data) {    
    const request = await axios.get(API_PATH+"/cate", {
        headers: {
            "Authorization": localStorage.getItem('TaxToken')
        },
    }).then((res) => res.data);

    return {
        type: CATE,
        payload: request
    }
} 


export async function selectCateTaxInvoice(data) {    
    const request = await axios.post(API_PATH+"/cate/tax",data, {
        headers: {
            "Authorization": localStorage.getItem('TaxToken')
        },
    }).then((res) => res.data);

    return {
        type: CATE_TAX,
        payload: request
    }
} 
export async function selectAccountingCodeDetails(data) {    
    const request = await axios.post(API_PATH+"/office/select/code",data, {
        headers: {
            "Authorization": localStorage.getItem('TaxToken')
        },
    }).then((res) => res.data);

    return {
        type: "selectAccountingCodeDetails",
        payload: request
    }
}



export async function CreateTaxInvoiceBill(data) {    
    const request = await axios.post(API_PATH+"/office/create/tax-invoice",data, {
        headers: {
            "Authorization": localStorage.getItem('TaxToken')
        },
    }).then((res) => res.data);

    return {
        type: "CreateTaxInvoiceBill",
        payload: request
    }
}


