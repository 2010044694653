

import AdbIcon from '@mui/icons-material/Adb';
import ExitToAppOutlined from '@mui/icons-material/ExitToAppOutlined';
import MenuIcon from '@mui/icons-material/Menu';
import { Divider, Drawer, ListItem, ListItemButton, ListItemText } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { connect } from 'react-redux';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import { List } from 'reactstrap';
import Footer from '../Components/Footer';
import PropTypes from 'prop-types';
import { useEffect } from 'react';


function DashboardLayout(props) {
    const { window } = props;
    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const navigate = useNavigate();
    const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState);
    };


    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

   



   
    const LogOut = () => {
        localStorage.clear();
        navigate("/")
    }

    const container = window !== undefined ? () => window().document.body : undefined;
    const navItems = [
        { title: 'ໜ້າຫຼັກຂອງລະບົບ', link: '/dashboard' },
        { title: 'ອອກໃບເກັບເງິນ', link: '/dashboard/create-tax-invoice' },
        { title: 'ລາຍການໃບເກັບເງິນ', link: '/dashboard/list-tax-invoice' },
        { title: 'ກ່ຽວກັບເຮົາ', link: '/dashboard/company' },
    ];
    const drawerWidth = 240;

    const drawer = (
        <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
            <Typography variant="h6" sx={{ my: 2 }}>
                TaxInvoice.la
            </Typography>
            <Divider />
            <List>
                {navItems.map((item, index) => (
                    <Link key={index} to={item.link}>
                        <ListItem key={index} >
                            <ListItemButton >
                                <ListItemText primary={item.title} />
                            </ListItemButton>
                        </ListItem>
                    </Link>
                ))}
            </List>
        </Box>
    );


    return (
        <div>
            <AppBar position="fixed">
                <Container maxWidth="xl">
                    <Toolbar disableGutters>
                        <AdbIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} />
                        <Typography
                            variant="h6"
                            noWrap
                            component="a"
                            href="/dashboard"
                            sx={{
                                mr: 2,
                                display: { xs: 'none', md: 'flex' },

                                fontWeight: 800,

                                color: 'white',
                                textDecoration: 'none',
                            }}
                        >
                            <b style={{ color: 'white' }}>TaxInvoice.la</b>
                        </Typography>

                        <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                            <IconButton
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleDrawerToggle}
                                color="inherit"
                            >
                                <MenuIcon />
                            </IconButton>

                        </Box>
                        <AdbIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} />
                        <Typography
                            variant="h5"
                            noWrap
                            component="a"
                            href=""
                            sx={{
                                mr: 2,
                                display: { xs: 'flex', md: 'none' },
                                flexGrow: 1,

                                fontWeight: 800,

                                color: 'white',
                                textDecoration: 'none',
                            }}
                        >
                            <b style={{ color: 'white' }}>TaxInvoice.la</b>
                        </Typography>
                        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex', color: 'white' } }}>
                            {navItems.map((page, index) => (
                                <Button
                                    href={page.link}
                                    key={index}
                                    onClick={handleCloseNavMenu}
                                    style={{ my: 2, color: 'white', display: 'block', fontSize: "16px" }}
                                >
                                    <b style={{ color: 'white' }}> {page.title}</b>
                                </Button>
                            ))}
                        </Box>

                        <Box sx={{ flexGrow: 0, }}>

                            <Button color='success' onClick={(e) => LogOut(e)} style={{ color: 'white', fontSize: "16px" }}>
                                <b>ອອກຈາກລະບົບ</b>    &nbsp;&nbsp;<ExitToAppOutlined />
                            </Button>


                        </Box>
                    </Toolbar>
                </Container>


            </AppBar>

            <Box component="nav">
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                >
                    {drawer}
                </Drawer>
            </Box>


            <div className='container-fluid'>
                <br></br>
                <br></br>
                <br></br>
                <Outlet />
            </div>
            <Footer />
        </div>
    );
}


DashboardLayout.propTypes = {
    window: PropTypes.func,
};




export default connect()(DashboardLayout);

