import React from 'react'
import { useEffect } from 'react';
import styles from './../../../Res/css/TaxInvoice.module.css'
export default function TableBody(props) {
    const value = []
    let indexLoop = 0;
    if(props.data<5){
        indexLoop=4-props.data;
    }else{
        indexLoop =8-props.data
    }
   
    for (let index = 0; index < indexLoop; index++) {
        value.push({ id: index })
    }



    return (
        value.map((item, index) => {
            return (
                <tr key={index} className={styles.table} style={{
                    height: "55px"
                }} >
                    <th scope="row"></th>
                    <td></td>
                    <td style={{ textAlign: 'center' }}></td>
                    <td style={{ textAlign: 'center' }}></td>
                    <td style={{ textAlign: 'right', borderBottomColor: 'AppWorkspace' }}></td>
                    <td style={{ textAlign: 'right' }}></td>
                </tr>
            )
        })
    );


}
