import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { selectAP_ACC_Gen } from '../../API/Action/Company.action';
import BreadcrumbFunction from '../../Components/BreadcrumbFunction';
import moment from 'moment';
import Loading from '../../Components/Loading';
const accountingLaoFormat = (inputNumber) => {

    let number = (Number(inputNumber)).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    var NumberCustom = "";
    number.split('').map(item => {
        if (item === ",") {
            NumberCustom += "."
        } else if (item === ".") {
            NumberCustom += ","
        } else {
            NumberCustom += item;
        }
    })

    return NumberCustom.toString();
}

function InvoiceList(props) {

    const dispatch = useDispatch();
    const [open, setOpen] = React.useState(false);
    const [loading, setLoading ]= React.useState(false);
    const [message, setMessage] = React.useState("");
    const [color, setColor] = React.useState("warning");
    const data = {
        open: open,
        message: message,
        color: color,
        loading: loading,
        progress: 0
    }



    useEffect(() => {
        setLoading(true)
        dispatch(selectAP_ACC_Gen({ company_id: localStorage.getItem("off_id") })).then(res=>{
            setLoading(false)
        })


    }, [dispatch])





    const ListBreadcrumb = [
        {
            link: '/dashboard',
            title: 'ໜ້າຫຼັກຂອງລະບົບ'
        },
        {
            link: '/dashboard/list-tax-invoice',
            title: 'ລາຍການໃບເກັບເງິນ'
        }]


    return (
        <div>
              <Loading data={data} />
            <BreadcrumbFunction data={ListBreadcrumb} />
            <center>
                <h3><b>ລາຍການໃບເກັບເງິນ</b></h3>
            </center>
            <div className="table-responsive">
                <table className="table table-hover table-striped table-bordered">
                    <thead style={{ backgroundColor: 'white' }}>
                        <tr>
                            <th scope="col">ເບິ່ງລາຍການ</th>
                            <th scope="col">ແກ້ໄຂລາຍການ</th>
                            <th scope="col">ຍົກເລີກລາຍການ</th>
                            <th scope="col">ເລກໃບເກັບເງິນ</th>
                            <th scope="col">ຊື່ລູກຄ້າ</th>
                            <th scope="col">ຈຳນວນເງິນ</th>
                            <th scope="col">ວັນທີ</th>
                            <th scope="col">ສະຖານະການສົ່ງຫາອາກອນ</th>
                        </tr>
                    </thead>
                    <tbody>
                     
                        {
                            props.AP_ACC_Gen ? props.AP_ACC_Gen.data.map((item, index) => {
                                return (
                                    <tr key={index}>
                                        <th scope="row"><a className='btn btn-success' type="button"   href={`/dashboard/bill-tax-invoice/${item.Com_id}/${item.certify}`}><b style={{color:'white'}}>ເບິ່ງໃບເກັບເງິນ</b></a></th>
                                        <th scope="row"><a className='btn btn-primary' type="button"   href={`/dashboard/update-tax-invoice/${item.Com_id}/${item.certify}`}><b style={{color:'white'}}>ແກ້ໄຂໃບເກັບເງິນ</b></a></th>
                                        <th scope="row">
                                            {/* <a className='btn btn-primary' type="button"   href={`/dashboard/bill-tax-invoice/${item.Com_id}/${item.certify}`}><b style={{color:'white'}}>ແກ້ໄຂໃບເກັບເງິນ</b></a> */}
                                            </th>
                                        <th scope="row">{item.certify}</th>
                                        <td>{item.Name_c}</td>    
                                        <td style={{textAlign:'right'}}>{accountingLaoFormat(item.TotalAmountCr) }{ "  "} { item.Curr}</td>    
                                        <td>{moment(item.date_work).format("DD-MM-YYYY")  }</td>
                                        <td></td>

                                    </tr>
                                )
                            }) : <></>
                        }




                    </tbody>
                </table>
            </div>

            <br />
        </div >
    )

}


function mapStateToProps(state) {
    return {
        AP_ACC_Gen: state.Company.ap_acc_gen,

    }
}


export default connect(mapStateToProps)(InvoiceList);

