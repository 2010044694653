import { MenuItem, Select } from '@mui/material';
import React, { useState } from 'react';



const Footer = () => {
    const [lang, setLang] = useState(1);
    return (
        <div>
            <div>
              
                <div >
                    <footer className="text-white text-center text-lg-start" style={{ backgroundColor: "#0564c8" }}>

                        <div className="container p-4">

                            <div className="row mt-4">

                                <div className="col-lg-4 col-md-12 mb-4 mb-md-0">
                                    <h5 className="text-uppercase mb-4">ກ່ຽວກັບພວກເຮົາ</h5>

                                    <p>
                                    Founded in 2021, Technology and
Software Developments company is a
provider of IT consulting and software
development services. Having started as
a small AI product company, we switched
to IT services and ever since we have
helped non-IT organizations and software
product companies improve business
performance and quickly win new
customers.
                                    </p>

                                    

                                </div>



                                <div className="col-lg-4 col-md-6 mb-4 mb-md-0">
                                    <h5 className="text-uppercase mb-4 pb-1">ທີ່ຕັ້ງຂອງພວກເຮົາ</h5>

                                    <ul className="fa-ul" >
                                        <li className="mb-3">
                                            <span className="fa-li"><i className="fas fa-home"></i></span><span className="ms-2">Thadeua Road, Thintom Village,
Hadxaifong District, Vientiane Capital</span>
                                        </li>
                                        <li className="mb-3">
                                            <span className="fa-li"><i className="fas fa-envelope"></i></span><span className="ms-2">info@bansilao.com</span>
                                        </li>
                                        <li className="mb-3">
                                            <span className="fa-li"><i className="fas fa-phone"></i></span><span className="ms-2">(+856) 2093549957</span>
                                        </li>
                                        <li className="mb-3">
                                            <span className="fa-li"><i className="fas fa-print"></i></span><span className="ms-2">r (+856) 2092598985</span>
                                        </li>
                                    </ul>
                                </div>

                                <div className="col-lg-4 col-md-6 mb-4 mb-md-0">
                                    <h5 className="text-uppercase mb-4">ເວລາການເຮັດວຽກ</h5>

                                    <table className="table text-center text-white">
                                        <tbody className="font-weight-normal">
                                            <tr>
                                                <td>ວັນຈັນ - ວັນສຸກ​</td>
                                                <td>8 AM - 4 PM</td>
                                            </tr>

                                        </tbody>
                                    </table>
                                    <h6 className="text-uppercase mb-4">ເລືອກພາສາ</h6>
                                    <Select
                                        size='small'
                                        labelId="demo-select-small"
                                        id="demo-select-small"
                                        value={lang}

                                        onChange={(e) => setLang(e.target.value)}
                                        color="info"
                                        className='language d-grid gap-2'
                                    >

                                        <MenuItem value={1}>Lao</MenuItem>
                                        <MenuItem value={2}>Eng</MenuItem>
                                    </Select>
                                </div>

                            </div>

                        </div>



                        <div className="text-center p-3" style={{ backgroundColor: "#044b97" }}>
                            © 2023 Copyright:
                            <a className="text-white" href="https://bansilao.com/"> www.bansilao.com</a>
                        </div>

                    </footer>

                </div>

            </div>
        </div>
    );
};

export default Footer;

