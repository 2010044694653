import React from 'react'

export default function FooterContent() {
    return (
        <div>
            <div className="row">
                <div> ຈຳນວນເງິນຂຽນເປັນຕົວໜັງສື:   <i>  ສິບສອງພັນ  </i> ກິບ</div>
                <div className="col-6">
                    <center>
                        ວັນທີ:.........................
                        <br />
                        <b>
                            ຜູ້ຊື້
                        </b>
                        <br />
                        (ລາຍເຊັນ ແລະ ປະທັບຕາ)
                    </center>
                </div>
                <div className="col-6">
                    <center>
                        <center>
                            ວັນທີ:.........................
                            <br />
                            <b>
                                ຜູ້ຂາຍ
                            </b>
                            <br />
                            (ລາຍເຊັນ ແລະ ປະທັບຕາ)
                        </center>
                    </center>
                </div>
            </div>
        </div>
    )
}
