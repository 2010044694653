

import React from 'react'
import QRCode from 'react-qr-code'
import { connect } from 'react-redux'
import { accountingLaoFormat, URL_QR } from '../../../API/Type/Type'
import styles from './../../../Res/css/TaxInvoice.module.css'
import TableBodyLoop from './TableBodyLoop'
function BillBodyLayerLoop(props) {



  return (
    <div>
      <table className="table table-bordered table-sm" >
        <thead style={{ border: "1px" }}>
          <tr>
          <th scope="col" style={{ width: "10px" , textAlign:'center' }}>ລ/ດ</th>
            <th scope="col" style={{ width: "150px", textAlign:'center' }}>ເນື້ອໃນລາຍການ</th>
            <th scope="col" style={{ width: "50px" , textAlign:'center'}}>ຫົວໜ່ວຍ</th>
            <th scope="col" style={{ width: "20px" , textAlign:'center'}}>ຈຳນວນ</th>
            <th scope="col" style={{ width: "80px" , textAlign:'center'}}>ລາຄາຫົວໜ່ວຍ</th>
            <th scope="col" style={{ width: "90px", textAlign:'center' }}>ລວມເປັນເງິນ</th>
          </tr>
        </thead>
        <tbody >


          {

            props.TAXI_INVOICE ? props.TAXI_INVOICE.data.body.map((item, index) => {
              return (
                index >= (props.TAXI_INVOICE ? props.TAXI_INVOICE.data.body.length >= 9 ? 9 : 9 : 0) ?
                  <tr key={index} className={styles.table} >
                    <th scope="row">{item.descrip !== "" ? (index + 1) : <>{"...."}</>}</th>
                    <td ><div className={styles.text}>{item.descrip}</div></td>
                    <td style={{ textAlign: 'center' }}>{item.unit_c}</td>
                    <td style={{ textAlign: 'center' }}>{item.Amount_c}</td>
                    <td style={{ textAlign: 'right' }}>{accountingLaoFormat(item.descrip !== "" ? item.Price_c : 0)}</td>
                    <td style={{ textAlign: 'right' }}>{accountingLaoFormat(item.descrip !== "" ? item.Price_c * item.Amount_c : 0)}</td>
                  </tr>
                  : <></>
              )
            }) : <></>
          }


{
    props.TAXI_INVOICE ? props.TAXI_INVOICE.data.body.length>0 ?
    <TableBodyLoop data={props.TAXI_INVOICE.data.body.length} />
    :<></>  :<></>
}
         


          <>
            < tr key={props.TAXI_INVOICE ? props.TAXI_INVOICE.data.body.length + 1 : 1} style={{ borderTop: "1px solid", borderBottom: "none" }}>
              <td colSpan="2" rowSpan="3" style={{
                border: 'none',
                textAlign: 'right'
              }} >
                <center >
                  {
                    console.log(URL_QR + "/" + (props.TAXI_INVOICE ? (props.TAXI_INVOICE.data.header[0].Com_id + "/" + props.TAXI_INVOICE.data.header[0].certify) : ''))
                  }
                  <QRCode value={URL_QR + "/" + (props.TAXI_INVOICE ? (props.TAXI_INVOICE.data.header[0].Com_id + "/" + props.TAXI_INVOICE.data.header[0].certify) : '')} size={70} style={{ marginTop: "10px" }} />
                  <br />
                  ດາວໂຫຼດໃບເກັບເງິນແບບອອນລາຍ
                </center>

              </td>
              <td colSpan="3" style={{
                border: 'none',
                textAlign: 'right'
              }} >
                ລວມມູນຄ່າຂາຍບໍ່ມີອາກອນ ({props.TAXI_INVOICE ? props.TAXI_INVOICE.data.header[0].Curr === "LAK" ? 'ກີບ' : props.TAXI_INVOICE.data.header[0].Curr : ''}):
              </td>
              <td style={{ border: "1px solid", textAlign: 'right' }}>
                {accountingLaoFormat((props.TAXI_INVOICE ? props.TAXI_INVOICE.data.header[0].amount : 0) - (props.TAXI_INVOICE ? props.TAXI_INVOICE.data.header[0].totalAmp_c : 0))}
              </td>
            </tr>
            <tr key={props.TAXI_INVOICE ? props.TAXI_INVOICE.data.body.length + 2 : 2} className={styles.td}>
              <td colSpan="3" style={{ border: 'none', textAlign: 'right' }}>
                <>{props.TAXI_INVOICE ? props.TAXI_INVOICE.data.header[0].pcenAmp_c_name : 0}</> , ເປັນຈຳນວນເງິນ ({props.TAXI_INVOICE ? props.TAXI_INVOICE.data.header[0].Curr === "LAK" ? 'ກີບ' : props.TAXI_INVOICE.data.header[0].Curr : ''}):
              </td>
              <td style={{ border: "1px solid", textAlign: 'right' }} >
                {accountingLaoFormat(props.TAXI_INVOICE ? props.TAXI_INVOICE.data.header[0].totalAmp_c : 0)}
              </td>
            </tr>
            <tr key={props.TAXI_INVOICE ? props.TAXI_INVOICE.data.body.length + 3 : 3} className={styles.td} >
              <td colSpan="3" style={{ border: 'none', textAlign: 'right' }}>
                <b>ລວມມູນຄ່າທັງໝົດ ({props.TAXI_INVOICE ? props.TAXI_INVOICE.data.header[0].Curr === "LAK" ? 'ກີບ' : props.TAXI_INVOICE.data.header[0].Curr : ''}):</b>
              </td>
              <td style={{ border: "1px solid", textAlign: 'right' }}>
                {accountingLaoFormat(props.TAXI_INVOICE ? props.TAXI_INVOICE.data.header[0].amount : 0)}
              </td>
            </tr>
          </>       
        </tbody>
      </table>
    </div >
  )
}


function mapStateToProps(state) {
  return {
    TAXI_INVOICE: state.Company.tax_invoice,

  }
}
export default connect(mapStateToProps)(BillBodyLayerLoop)