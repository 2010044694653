import { Card } from '@mui/material'
import React from 'react'

export default function Developing() {
    return (
        <div>
            <Card style={{
                padding:"10px"
            }}>
                <center>
                   <h1>ກຳລັງຢູ່ໃນຂ້ນຕອນການພັດທະນາ</h1>
                   <h1>We are Developing</h1>
                   <h1>Coming soon</h1>
                </center>
            </Card>
        </div>
    )
}
