import { AP_ACC_Gen, ASSET_ACCOUNT, CATE, CATE_TAX, CREATE_ACCOUNT, CUSTOMER_ACCOUNT, DISTRICT, PROVINCE, SELECT_ACCOUNT_BY_CODE,  TAX_INVOICE, VILLAGE } from "../Type/Type";


const Company = (state = {}, action) => {
    switch (action.type) {
        case PROVINCE:
            return { ...state, province: action.payload }
        case DISTRICT:
            return { ...state, district: action.payload }
        case VILLAGE:
            return { ...state, village: action.payload }
        case CUSTOMER_ACCOUNT:
            return { ...state, customer_acc: action.payload }
        case ASSET_ACCOUNT:
            return { ...state, asset_acc: action.payload }
        case CREATE_ACCOUNT:
            return { ...state, create_acc: action.payload }
        case SELECT_ACCOUNT_BY_CODE:
            return { ...state, account_acc_code: action.payload }
        case CATE:
            return { ...state, cate: action.payload }
        case CATE_TAX:
            return { ...state, cate_tax: action.payload }
        case AP_ACC_Gen:
            return { ...state, ap_acc_gen: action.payload }
            case TAX_INVOICE:
                return { ...state, tax_invoice: action.payload }
            
        default:
            return state;
    }
};

export default Company;
