import React from 'react'
import { connect } from 'react-redux'
import { accountingLaoFormat } from '../../../API/Type/Type'
import styles from './../../../Res/css/TaxInvoice.module.css'


 function HeaderCustomer(props) {
    return (
        <div>
          
            <div className="row">

                <div>
                    <b>
                        ຊື່ວິສາຫະກິດ (ຜູ້ຂາຍ)  {props.TAXI_INVOICE ?  props.TAXI_INVOICE.data.office[0].off_nm:0}
                    </b>
                </div>
                <div>
                <>ບ້ານ :</>{props.TAXI_INVOICE ?  props.TAXI_INVOICE.data.office[0].off_StrtL:''}
                </div>
                <div>
                    ເລກບັນຊີທະນາຄານ: {props.TAXI_INVOICE ?  props.TAXI_INVOICE.data.office[0].Name_Bnak:''}, ຊື່ບັນຊີທະນາຄານ: {props.TAXI_INVOICE ?  props.TAXI_INVOICE.data.office[0].meBank_code:''}
                    <br /> ໂທລະສັບ: {props.TAXI_INVOICE ?  props.TAXI_INVOICE.data.office[0].Tel:''}
                </div>

                <div>
                    ເລກປະຈຳຕົວຜູ້ເສຍອາກອນ: {props.TAXI_INVOICE ?  props.TAXI_INVOICE.data.office[0].off_NoL:''}
                </div>

            </div>

            <hr style={{ margin: "5px" }} />

         
                <div>
                    <b>
                        ຊື່ວິສາຫະກິດ (ຜູ້ຊື້) {props.TAXI_INVOICE ?  props.TAXI_INVOICE.data.header[0].Name_c:0}
                    </b>
                </div>
                <div>
                <>ບ້ານ :</>{props.TAXI_INVOICE ?  props.TAXI_INVOICE.data.header[0].Ban_c:''}, <>ເມືອງ :</>{props.TAXI_INVOICE ?  props.TAXI_INVOICE.data.header[0].Meung_c:''} <>ແຂວງ :</>{props.TAXI_INVOICE ?  props.TAXI_INVOICE.data.header[0].Khaeng_c:''}
                
                </div>
                <div>
                    ເລກບັນຊີທະນາຄານ: {props.TAXI_INVOICE ?  props.TAXI_INVOICE.data.header[0].namebank_c:''}, ຊື່ບັນຊີທະນາຄານ: {props.TAXI_INVOICE ?  props.TAXI_INVOICE.data.header[0].Bankcode_c:''}
                    <br /> ໂທລະສັບ: {props.TAXI_INVOICE ?  props.TAXI_INVOICE.data.header[0].Tel_c:''}
                </div>

                <div className="row">
                    <div className="col-8">
                    ເລກປະຈຳຕົວຜູ້ເສຍອາກອນ: {props.TAXI_INVOICE ?  props.TAXI_INVOICE.data.header[0].Licen_Tax:''}
                    </div>
                    <div className="col-4" style={{textAlign:'right'}}>
                 {props.TAXI_INVOICE ?  props.TAXI_INVOICE.data.header[0].Curr!=="LAK" ? <>ອັດຕາແລກປ່ຽນ: {accountingLaoFormat(Number(props.TAXI_INVOICE ?  props.TAXI_INVOICE.data.header[0].Rate:''))}</>:"":""}   
                        </div>
                 
                </div>
                <div  className={styles.textRemark}>
                    <b>
                        ໝາຍເຫດ:  </b> {props.TAXI_INVOICE ?  props.TAXI_INVOICE.data.header[0].Loacation:''} 
                   
                
            </div>
        </div>
    )
}


function mapStateToProps(state) {
    return {
        TAXI_INVOICE: state.Company.tax_invoice,
  
    }
  }
  export default connect(mapStateToProps)(HeaderCustomer)