import { Button, Card } from '@mui/material'
import React from 'react'
import BreadcrumbFunction from '../../Components/BreadcrumbFunction'
import styles from './../../Res/css/TaxInvoice.module.css'
export default function DashboardAdmin() {



    const ListBreadcrumb = [
        {
            link: '/dashboard',
            title: 'ໜ້າຫຼັກຂອງລະບົບ'
        },

    ]
    return (
        <div>
            <br />
            <center>
                <h3 style={{ textAlign: 'center' }}> <b>ສະບາຍດີ TaxInvoice.la ຍິນດີໃຫ້ບໍລິການໃບເກັບເງິນແບບອອນລາຍ</b></h3>

            </center>

            <div className={styles.page_demo} >

            </div>


        </div>
    )
}
