import { Alert, Button, Card, Container, Snackbar, Stack } from '@mui/material';
import React from 'react';
// import { useDispatch } from 'react-redux';
// import { useNavigate, useParams } from "react-router-dom";
import { Form, FormGroup, Input, Label } from "reactstrap";
import AppBarHeader from '../Components/AppBar';



export default function ResetPassword() {

    // const [password, SetPassword] = useState("");
    // const [confirm_password, SetConfirm_password] = useState("");
    const [open, setOpen] = React.useState(false);
    // let navigate = useNavigate();
    // const { reset_token } = useParams();
    // const dispatch = useDispatch();

    const handleClose = () => { 
        setOpen(false);
    };

    return (
        <div>
            <AppBarHeader />
            <Stack spacing={2} sx={{ width: '100%' }}>
                <Snackbar anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                    open={open} autoHideDuration={6000} onClose={handleClose}>
                    <Alert onClose={handleClose} variant="filled" severity="warning" sx={{ width: '100%' }}>
                        ລະຫັດຜ່ານບໍ່ຕົງກັນກະລຸນາປ້ອນລະຫັດຜ່ານໃຫ້ຖືກຕ້ອງ
                    </Alert>
                </Snackbar>
            </Stack>
            <div className='center_center_top'>
                <div className="center_center">

                    <Card style={{ maxWidth: 700, }}>

                        <div className="login_header">
                            <br></br>

                            {/* <Avatar className="login_logo" alt="LOGO" src="./logo.png" /> */}


                            <h2><b>ຂັນຕອນການປ່ຽນລະຫັດຜ່ານຂອງລະບົບ</b></h2>
                            <h5><b>ກະລຸນາຕັ້ງລະຫັດຜ່ານໃຫ້ມີຄວາມປວດໄພ</b></h5>
                        </div>
                        <hr />

                        <Container>

                            <div >
                                <br></br>
                            </div >

                            <Form >
                                <FormGroup>
                                    <Label for="exampleEmail">
                                        ປ້ອນລະຫັດຜ່ານໃຫມ່
                                    </Label>
                                    <Input
                                        id="exampleEmail"
                                        name="password"
                                        placeholder="  ປ້ອນລະຫັດຜ່ານໃຫມ່"
                                        type="password"
                                        // onChange={(e) => SetPassword(e.target.value)}
                                    />

                                </FormGroup>
                                <FormGroup>
                                    <Label for="exampleEmail">
                                        ຢືນຢັນລະຫັດຜ່ານ
                                    </Label>
                                    <Input
                                        id="exampleEmail"
                                        name="confirm_password"
                                        placeholder="  ຢືນຢັນລະຫັດຜ່ານ"
                                        type="password"
                                        // onChange={(e) => SetConfirm_password(e.target.value)}
                                    />

                                </FormGroup>



                                <div className='row'>
                                    <div className='col-6'>
                                        <div className="d-grid gap-2 d-md-block">
                                            <Button variant='contained' color="success">ຂໍລະຫັດໃໝ່</Button>
                                        </div>
                                    </div>
                                    <div className='col-6'>
                                        <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                            <Button href="/forgot-password" variant='contained' color='default'  >ລຶມລະຫັດຜ່ານ ?</Button>
                                        </div>
                                    </div>
                                </div>

                            </Form>
                            <br></br>
                        </Container>
                    </Card>
                </div>
            </div>
        </div>

    )
}
